import { useCallback, useEffect } from 'react';
import { DateRangePicker, RangeKeyDict } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { ALL_ACTIVE_BUSINESS_UNITS, linesOfBusiness } from '../../constants';
import Select from '../../layout/form/Select';
import SubmitButton from '../../layout/form/SubmitButton';
import { useAccountingPageState } from './AccountingPageProvider';
import { actions } from './reducer';
import { useAccountingPageSearch } from './useAccountingPageSearch';

export const AccountingPageSearchCriteria = () => {
    const {
        state: { displayCriteria, accountNumberResults, isLoading },
        dispatch
    } = useAccountingPageState();
    const { getAccountNumbersResult, getAccountSummariesResult } = useAccountingPageSearch();

    useEffect(() => {
        getAccountNumbersResult();
    }, [getAccountNumbersResult]);

    const onChangeDate = useCallback(
        (ranges: RangeKeyDict) => {
            if (ranges.selection.startDate)
                dispatch({
                    type: actions.CHANGE_START_DATE,
                    beginDate: ranges.selection.startDate
                });
            if (ranges.selection.endDate)
                dispatch({
                    type: actions.CHANGE_END_DATE,
                    endDate: ranges.selection.endDate
                });
        },
        [dispatch]
    );

    const onChangeAccountNumber = useCallback(
        (e: { id: string; value: string }) => {
            dispatch({ type: actions.CHANGE_ACCOUNT_NUMBER, accountNumber: e.value || undefined });
        },
        [dispatch]
    );

    const onChangeLineOfBusiness = useCallback(
        (e: { id: string; value: string }) => {
            // TODO: Use results of multi select dropdown event change to set linesOfBusiness (notice the s after line)
            // Downstream (BFF) will just pass through provided criteria
            // Reporting app expects a field called 'linesOfBusiness' to be a string array
            const linesOfBusiness = e.value !== '' ? [e.value] : ALL_ACTIVE_BUSINESS_UNITS;
            dispatch({ type: actions.CHANGE_LINE_OF_BUSINESS, linesOfBusiness: linesOfBusiness });
        },
        [dispatch]
    );

    const selectionRange = {
        startDate: new Date(displayCriteria.beginDate),
        endDate: new Date(displayCriteria.endDate),
        key: 'selection'
    };

    const items = accountNumberResults?.map((item) => ({ code: item.value, name: item.value })) || [];

    return (
        <div
            className={'pds-util-flex'}
            style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                alignContent: 'start',
                maxWidth: '70%'
            }}
        >
            <div>
                <DateRangePicker ranges={[selectionRange]} onChange={onChangeDate} />
            </div>

            <div style={{ minWidth: '20%' }}>
                <Select
                    id="accountNumber"
                    label="Account Number"
                    items={items}
                    showBlankOption={true}
                    onChange={onChangeAccountNumber}
                    className="input-sm"
                    required={false}
                />
                {/* <!-- TODO: Make this a multi select drop down --> */}
                <Select
                    id="lineOfBusiness"
                    label="Line of Business"
                    items={linesOfBusiness}
                    showBlankOption={true}
                    onChange={onChangeLineOfBusiness}
                    className="input-sm"
                    required={false}
                />
            </div>

            <div>
                <SubmitButton
                    label={'Search'}
                    color={'success'}
                    loadingLabel={'...'}
                    className={'btn-sm pull-right button-pull-up'}
                    onClick={getAccountSummariesResult}
                    isLoading={isLoading}
                />
            </div>
        </div>
    );
};
