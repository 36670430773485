import { Navigate, Route, HashRouter as Router, Routes } from 'react-router-dom';
import { DownloadReportPage } from '../app/download-report-page/DownloadReportPage';
import { DiscardedErrorsPage } from '../app/errors-page/discarded-errors/DiscardedErrorsPage';
import { FailedErrorsPage } from '../app/errors-page/failed-errors/FailedErrorsPage';
import { LandingPage } from '../app/landing-page/LandingPage';
import NotFound from '../app/layout/container/NotFound';
import { AccountingPage } from '../app/transactions-page/accounting/AccountingPage';
import { ProductPage } from '../app/transactions-page/product/ProductPage';
import { ProductMappingsPage } from '../app/product-mapping/ProductMappingsPage';
import { AccountsPage } from '../app/accounts/AccountsPage';
import { AccountsBuildPage } from '../app/accounts/build/AccountsBuildPage';

export default (
    <Router>
        <Routes>
            {/* Defined routes */}
            <Route path="/mappings/products" element={<ProductMappingsPage />} />
            <Route path="/mappings/accounts" element={<AccountsPage />} />
            <Route path="/mappings/accounts/build" element={<AccountsBuildPage />} />
            <Route path="/error-events/failed-errors" element={<FailedErrorsPage />} />
            <Route path="/error-events/discarded-errors" element={<DiscardedErrorsPage />} />
            <Route path="/transactions/accounting" element={<AccountingPage />} />
            <Route path="/transactions/product" element={<ProductPage />} />
            <Route path="/download/:id" element={<DownloadReportPage />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="error-events" element={<Navigate replace to="/error-events/failed-errors" />} />
            <Route path="transactions" element={<Navigate replace to="/transactions/accounting" />} />
            <Route path="mappings" element={<Navigate replace to="/mappings/products" />} />
            {/* Where to go if no route is found */}
            <Route path="*" element={<NotFound />} />
        </Routes>
    </Router>
);
