import { DownloadReportState } from './DownloadReportState';

export const defaultInitialState: DownloadReportState = {
    isLoading: false,
    isDownloading: false
};

export const enum actions {
    INITIALIZE_DOWNLOAD_REPORT_REQUEST = 'INITIALIZE_DOWNLOAD_REPORT_REQUEST',
    DOWNLOAD_REPORT_STARTED = 'DOWNLOAD_REPORT_STARTED',
    DOWNLOAD_REPORT_SUCCESS = 'DOWNLOAD_REPORT_SUCCESS',
    DOWNLOAD_REPORT_FAILED = 'DOWNLOAD_REPORT_FAILED'
}

export type Action =
    | { type: actions.INITIALIZE_DOWNLOAD_REPORT_REQUEST }
    | { type: actions.DOWNLOAD_REPORT_STARTED }
    | { type: actions.DOWNLOAD_REPORT_SUCCESS }
    | { type: actions.DOWNLOAD_REPORT_FAILED };

export function DownloadReportReducer(state: DownloadReportState, action: Action): DownloadReportState {
    switch (action.type) {
        case actions.INITIALIZE_DOWNLOAD_REPORT_REQUEST:
            return {
                ...state,
                isDownloading: false,
                isLoading: true
            };
        case actions.DOWNLOAD_REPORT_STARTED:
            return {
                ...state,
                isDownloading: true,
                isLoading: false
            };
        case actions.DOWNLOAD_REPORT_SUCCESS:
            return {
                ...state,
                isDownloading: false,
                isLoading: false
            };
        case actions.DOWNLOAD_REPORT_FAILED:
            return {
                ...state,
                isDownloading: false,
                isLoading: false
            };
        default:
            throw new Error(`Unhandled action type: ${(action as unknown as Action).type}`);
    }
}
