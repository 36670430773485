import { IPublicClientApplication } from '@azure/msal-browser';
import axios from 'axios';
import { getSilentRequest } from '../app/authentication/get-silent-request';

export async function post<T>(url: string, instance: IPublicClientApplication, body: object): Promise<T> {
    const tokenResponse = await instance.acquireTokenSilent(getSilentRequest());

    const config = {
        headers: {
            Authorization: `Bearer ${tokenResponse.accessToken}`
        }
    };

    const response = await axios.post(url, body, config);
    return response.data;
}
