import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import router from './config/router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    msalConfigurationDev,
    msalConfigurationLocal,
    msalConfigurationNonprod,
    msalConfigurationProd
} from './app/authentication/msal-requests';

import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';

// principal design system style imports
import '@pds-react/core/dist/core.min.css';
import '@pds-react/animatedIcon/dist/animatedIcon.min.css';
import '@pds-react/collapsible/dist/collapsible.min.css';
import '@pds-react/enterpriseFooter/dist/enterpriseFooter.min.css';
import '@pds-react/grid/dist/grid.min.css';

let msalConfiguration;
switch (window.location.hostname) {
    case 'accounting.salescompadmin.dev.principal.com':
        msalConfiguration = msalConfigurationDev;
        break;
    case 'accounting.salescompadmin.staging.principal.com':
        msalConfiguration = msalConfigurationNonprod;
        break;
    case 'localhost':
        msalConfiguration = msalConfigurationLocal;
        break;
    default:
        msalConfiguration = msalConfigurationProd;
        break;
}

const msalInstance = new PublicClientApplication(msalConfiguration);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={{
                    scopes: ['openid', 'profile']
                }}
            >
                <ToastContainer
                    position="top-right"
                    autoClose={false}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                    pauseOnHover
                />

                {router}
            </MsalAuthenticationTemplate>
        </MsalProvider>
    </React.StrictMode>
);
