import * as PropTypes from 'prop-types';
import { Rule } from 'shared/src/domain/ProductAccountMapping';
import AccountsModal from './AccountsModal';
import { useProductMappingsState } from './ProductMappingsProvider';

const ProductMappingRulesTableLineItem = (props: { dynamicHeaders: string[]; rule: Rule }) => {
    const { rule, dynamicHeaders } = props;
    const {
        state: { accounts }
    } = useProductMappingsState();

    return (
        <>
            <AccountsModal id={rule._id} accounts={accounts.filter((account) => rule.accounts.includes(account._id))} />
            <tr>
                {dynamicHeaders.map((header, i) => (
                    <td key={i}>
                        {rule.attributes
                            .find((attribute) => attribute.code === header)
                            ?.values.map((value) => <div>{value.text}</div>) || 'N/A'}
                    </td>
                ))}
                <td>
                    <a
                        href="#"
                        data-target={'.view-accounts' + rule._id}
                        data-toggle={'modal'}
                        title="Click for Account Details"
                    >
                        view
                    </a>
                </td>
            </tr>
        </>
    );
};

ProductMappingRulesTableLineItem.propTypes = {
    rule: PropTypes.object.isRequired,
    dynamicHeaders: PropTypes.array.isRequired
};

export default ProductMappingRulesTableLineItem;
