import { createContext, Dispatch, ReactNode, useContext, useReducer } from 'react';
import { Action, defaultInitialState, FailedErrorsReducer } from './reducer';
import { ErrorsPageState } from '../ErrorsPageState';

const FailedErrorsContext = createContext(undefined as never as { state: ErrorsPageState; dispatch: Dispatch<Action> });

export function useFailedErrorsState() {
    const context = useContext(FailedErrorsContext);
    if (context === undefined) {
        throw new Error('useFailedErrorsState must be used within a FailedErrorsProvider');
    }
    return context;
}

export const FailedErrorsProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(FailedErrorsReducer, defaultInitialState);
    return <FailedErrorsContext.Provider value={{ state, dispatch }}>{children}</FailedErrorsContext.Provider>;
};
