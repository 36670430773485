import { camelCaseToTitle } from '../../utils';
import { useAccountingPageState } from './AccountingPageProvider';
import AccountingTableLineItem from './AccountingTableLineItem';

export const AccountingResultsTable = () => {
    const {
        state: { searchResults }
    } = useAccountingPageState();

    const dynamicColumnHeaders = Array.from(
        new Set(
            searchResults?.accounts
                ?.flatMap((summary) => summary?.account?.attributes?.map((attribute) => attribute?.code))
                ?.filter(Boolean)
        )
    );
    const formattedDynamicColumnHeaders = dynamicColumnHeaders.map(camelCaseToTitle);

    return (
        <div className="table-responsive">
            <table
                id="accountingResults"
                className="table table-hover no-footer"
                role="grid"
                aria-describedby="accountingResults_info"
            >
                <thead className="text-nowrap">
                    <tr>
                        <th className="sorting" tabIndex={0} aria-controls="accountingResults" aria-sort="descending">
                            Name
                        </th>
                        <th className="sorting" tabIndex={0} aria-controls="accountingResults" aria-sort="descending">
                            Number
                        </th>
                        <th className="sorting" tabIndex={0} aria-controls="accountingResults" aria-sort="descending">
                            Type
                        </th>
                        {formattedDynamicColumnHeaders.map((header, i) => (
                            <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="accountingResults"
                                aria-sort="descending"
                                key={i}
                            >
                                {header}
                            </th>
                        ))}
                        <th className="sorting" tabIndex={0} aria-controls="accountingResults" aria-sort="descending">
                            Amount
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {searchResults?.accounts?.map((result, i) => {
                        return (
                            <AccountingTableLineItem
                                key={i}
                                dynamicHeaders={dynamicColumnHeaders}
                                accountSummary={result}
                            />
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
