import React, { ReactNode } from 'react';
import * as PropTypes from 'prop-types';

const FormGroup = (props: {
    children: ReactNode;
    id: string;
    showError: boolean;
    fieldErrors?: { [key: string]: string };
}) => {
    const { children, id, showError, fieldErrors } = props;

    const fieldError = fieldErrors && fieldErrors[id];
    return (
        <div className={`form-group ${fieldError ? ' has-error' : ''}`}>
            {children}
            {showError && <span className="app-field-error">{fieldError}</span>}
        </div>
    );
};

FormGroup.propTypes = {
    showError: PropTypes.bool,
    id: PropTypes.string.isRequired
};

FormGroup.defaultProps = {
    showError: true
};

export default FormGroup;
