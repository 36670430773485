import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../layout/loading/Loading';
import { useDownloadReportState } from './DownloadReportProvider';
import { useDownloadReportSearch } from './useDownloadReportSearch';

export const DownloadReportContainer = () => {
    const {
        state: { isLoading, isDownloading }
    } = useDownloadReportState();
    const { getReportFile } = useDownloadReportSearch();
    const { id } = useParams();

    useEffect(() => {
        getReportFile(id!);
    }, [getReportFile]);

    return <div>{(isLoading || isDownloading) && <Loading />}</div>;
};
