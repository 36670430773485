import React, { MouseEvent } from 'react';
import * as PropTypes from 'prop-types';
import { doNothingWithEvent } from '../../utils';

const PageNavigationLeftButtons = (props: {
    disabled: boolean;
    onGoBack: (event: MouseEvent) => void;
    onGoToBeginning: (event: MouseEvent) => void;
    showGoToBeginning: boolean;
}) => {
    const { disabled, onGoBack, onGoToBeginning, showGoToBeginning } = props;

    return (
        <>
            {showGoToBeginning && (
                <li className={disabled ? 'disabled' : ''}>
                    <a href="#" onClick={disabled ? doNothingWithEvent : onGoToBeginning}>
                        <i data-testid="pageable-single-arrow" className="fa fa-angle-left" />
                        <i data-testid="pageable-single-arrow" className="fa fa-angle-left" />
                    </a>
                </li>
            )}
            <li className={disabled ? 'disabled' : ''}>
                <a href="#" onClick={disabled ? doNothingWithEvent : onGoBack}>
                    <i data-testid="pageable-single-arrow" className="fa fa-angle-left" />
                </a>
            </li>
        </>
    );
};

PageNavigationLeftButtons.propTypes = {
    disabled: PropTypes.bool,
    showGoToBeginning: PropTypes.bool,
    onGoBack: PropTypes.func.isRequired,
    onGoToBeginning: PropTypes.func.isRequired
};

PageNavigationLeftButtons.defaultProps = {
    disabled: false,
    showGoToBeginning: true
};

export default PageNavigationLeftButtons;
