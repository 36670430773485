import { Product } from '../../models/Product';
import { ProductMappingsState } from './ProductMappingsState';

export const defaultInitialState: ProductMappingsState = {
    isLoading: false,
    selectedLineOfBusiness: '',
    selectedProductCode: '',
    searchExecuted: false,
    productCodes: {},
    productMapping: undefined,
    accounts: []
};

export const enum actions {
    SET = 'SET',
    SET_PRODUCT_CODES = 'SET_PRODUCT_CODES'
}

export type Action =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | { type: actions.SET; key: string; value: any }
    | { type: actions.SET_PRODUCT_CODES; lineOfBusiness: string; value: Product[] };

export function ProductMappingsReducer(state: ProductMappingsState, action: Action): ProductMappingsState {
    switch (action.type) {
        case actions.SET:
            return {
                ...state,
                [action.key]: action.value
            };
        case actions.SET_PRODUCT_CODES:
            return {
                ...state,
                productCodes: {
                    ...state.productCodes,
                    [action.lineOfBusiness]: action.value
                }
            };
        default:
            throw new Error(`Unhandled action type: ${(action as unknown as Action).type}`);
    }
}
