import { SyntheticEvent } from 'react';

export const doNothingWithEvent = (e: SyntheticEvent) => e.preventDefault();

export function formatDatetimeForDisplay(datetimeString: string | undefined) {
    const date = datetimeString ? Date.parse(datetimeString) : undefined;

    if (date) {
        return new Date(date).toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short'
        });
    } else {
        return '';
    }
}

export function formatDateForDisplay(dateString: string | undefined) {
    const date = dateString ? Date.parse(dateString) : undefined;

    if (date) {
        return new Date(date).toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            timeZone: 'UTC'
        });
    } else {
        return '';
    }
}

export function camelCaseToTitle(s: string) {
    const result = s.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
}
