import { isEmpty } from 'lodash';
import Loading from '../layout/loading/Loading';
import { ProductMappingResultsHeader } from './ProductMappingResultsHeader';
import { useProductMappingsState } from './ProductMappingsProvider';
import { ProductMappingRulesTable } from './ProductMappingRulesTable';
import { useEffect } from 'react';
import { useProductMappingsSearch } from './view/useProductMappingsSearch';

export const ProductMappingSearchResults = () => {
    const {
        state: { isLoading, productMapping, searchExecuted }
    } = useProductMappingsState();
    const { getAccounts } = useProductMappingsSearch();

    const hasResults = !isEmpty(productMapping);

    useEffect(() => {
        if (hasResults) getAccounts();
    }, [hasResults, getAccounts]);

    return (
        <>
            {isLoading && <Loading />}
            {!isLoading && searchExecuted && (
                <div style={{ borderTop: 'solid', paddingBlockStart: '10px' }}>
                    <div className="panel panel-default">
                        <div className="panel-body">
                            {hasResults ? (
                                <div>
                                    <ProductMappingResultsHeader />
                                    <ProductMappingRulesTable />
                                </div>
                            ) : (
                                <div className="text-center">No results were found!</div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
