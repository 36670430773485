import MainContainer from '../../layout/container/MainContainer';
import { AccountsBuildContainer } from './AccountsBuildContainer';

export function AccountsBuildPage() {
    return (
        <MainContainer section="mappings" secondarySection="accounts">
            <AccountsBuildContainer />
        </MainContainer>
    );
}
