import { useCallback } from 'react';
import { unpackPaging } from '../../layout/pageable/utils';
import { useFailedErrorsState } from './FailedErrorsProvider';
import { actions } from './reducer';
import { toast } from 'react-toastify';
import { PageInfo } from '../../layout/pageable/PageInfo';
import { ErrorEvent } from '../../../models/ErrorEvent';
import { useMsal } from '@azure/msal-react';
import { BackendApi } from '../../../api/backendApi';

export function useFailedErrorsSearch() {
    const { instance } = useMsal();
    const {
        state: { nextPage },
        dispatch
    } = useFailedErrorsState();

    const failedErrorEvents = useCallback(async () => {
        dispatch({ type: actions.INITIALIZE_SEARCH_REQUEST });

        let response: {
            data: ErrorEvent[];
            page: PageInfo;
        };

        try {
            response = await new BackendApi(instance).viewAllErrors(nextPage, 'FAILED');
            dispatch({
                type: actions.SEARCH_REQUEST_SUCCESS,
                page: unpackPaging(response.page),
                results: response.data || []
            });
        } catch (error) {
            console.error('failed to load search results: ', error);
            dispatch({ type: actions.SEARCH_REQUEST_FAILED });
            toast.error('Failed to load search results!', {
                toastId: 'failedErrorsFailed',
                autoClose: 5000
            });
        }
    }, [nextPage, dispatch]);

    return { failedErrorEvents };
}
