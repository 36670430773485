import { useMsal } from '@azure/msal-react';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { BackendApi } from '../../../api/backendApi';
import { Product } from '../../../models/Product';
import { useProductMappingsState } from '../ProductMappingsProvider';
import { actions } from '../reducer';
import { ProductAccountMapping } from 'shared/src/domain/ProductAccountMapping';

export function useProductMappingsSearch() {
    const { instance } = useMsal();
    const {
        state: { selectedLineOfBusiness, selectedProductCode, productMapping },
        dispatch
    } = useProductMappingsState();

    const getProductCodes = useCallback(async () => {
        dispatch({ type: actions.SET, key: 'isLoading', value: true });

        let response: Product[];

        try {
            response = await new BackendApi(instance).getProductCodes(selectedLineOfBusiness);
            dispatch({
                type: actions.SET_PRODUCT_CODES,
                lineOfBusiness: selectedLineOfBusiness,
                value: response
            });
            dispatch({
                type: actions.SET,
                key: 'isLoading',
                value: false
            });
        } catch (error) {
            console.error('failed to load search results: ', error);
            dispatch({
                type: actions.SET,
                key: 'isLoading',
                value: false
            });
            toast.error('Failed to load search results!', {
                toastId: 'ProductMappingsFailed',
                autoClose: 5000
            });
        }
    }, [dispatch, selectedLineOfBusiness]);

    const getProductMapping = useCallback(async () => {
        dispatch({ type: actions.SET, key: 'isLoading', value: true });

        let response: ProductAccountMapping[];

        try {
            response = await new BackendApi(instance).getProductMappings(
                selectedLineOfBusiness,
                selectedProductCode,
                'ACTIVE'
            );
            if (response.length > 1) {
                toast.error('Multiple Product Account Mappings met the search criteria', {
                    toastId: 'MultipleProductMappings',
                    autoClose: 5000
                });
            } else {
                dispatch({
                    type: actions.SET,
                    key: 'productMapping',
                    value: response[0]
                });
            }
            dispatch({
                type: actions.SET,
                key: 'searchExecuted',
                value: true
            });
            dispatch({
                type: actions.SET,
                key: 'isLoading',
                value: false
            });
        } catch (error) {
            console.error('Failed to retrieve Product Account Mapping: ', error);
            dispatch({
                type: actions.SET,
                key: 'isLoading',
                value: false
            });
            toast.error('Failed to retrieve Product Account Mapping', {
                toastId: 'ProductMappingsFailed',
                autoClose: 5000
            });
        }
    }, [dispatch, selectedLineOfBusiness, selectedProductCode]);

    const getAccounts = useCallback(async () => {
        try {
            const accountIds = [...new Set(productMapping?.rules?.flatMap((rule) => rule?.accounts)?.filter(Boolean))];
            const response = await new BackendApi(instance).getAccountsBatch({ ids: accountIds });
            dispatch({
                type: actions.SET,
                key: 'accounts',
                value: response
            });
        } catch (error) {
            console.error('Failed to retrieve Accounts: ', error);
            toast.error('Failed to retrieve Accounts', {
                toastId: 'AccountsFailed',
                autoClose: 5000
            });
        }
    }, [dispatch, productMapping]);

    return { getProductCodes, getProductMapping, getAccounts };
}
