import MainContainer from '../../layout/container/MainContainer';
import { FailedErrorsProvider } from './FailedErrorsProvider';
import FailedErrorsSearchContainer from './FailedErrorsSearchContainer';

export function FailedErrorsPage() {
    return (
        <MainContainer section="errorEvents" secondarySection="failedErrors">
            <FailedErrorsProvider>
                <FailedErrorsSearchContainer />
            </FailedErrorsProvider>
        </MainContainer>
    );
}
