import React from 'react';
import * as PropTypes from 'prop-types';

interface SecondaryNavigationProps {
    children: React.ReactNode;
}

export const SecondaryNavigation = (props: SecondaryNavigationProps) => {
    const { children } = props;

    return (
        <section className="nav-secondary-wrapper">
            <nav className="container-fluid">
                <div className="row">
                    <div className="col-xl-10 col-xl-offset-1 app-main-container">
                        <ul className="nav nav-secondary">{children}</ul>
                    </div>
                </div>
            </nav>
        </section>
    );
};

SecondaryNavigation.propTypes = {
    children: PropTypes.node.isRequired
};
