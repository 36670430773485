import MainContainer from '../../layout/container/MainContainer';
import { AccountingPageProvider } from './AccountingPageProvider';
import AccountingPageContainer from './AccountingPageContainer';

export function AccountingPage() {
    return (
        <MainContainer section="transactions" secondarySection="accounting">
            <AccountingPageProvider>
                <AccountingPageContainer />
            </AccountingPageProvider>
        </MainContainer>
    );
}
