import { AccountsSearchContainer } from './AccountsSearchContainer';
import { AccountsSearchResults } from './AccountsSearchResults';

export const AccountsContainer = () => {
    return (
        <>
            <AccountsSearchContainer />
            <AccountsSearchResults />
        </>
    );
};
