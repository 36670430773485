import { PageInfo } from './PageInfo';

export const ResultsPerPageOptions = [
    { code: 10, name: '10' },
    { code: 25, name: '25' },
    { code: 50, name: '50' }
];

export const DefaultPage: PageInfo = {
    page: 1,
    size: 10,
    totalElements: 0,
    totalPages: 0
};

const pageButtonsToRender = 5;
const zeroIndexedPageButtons = pageButtonsToRender - 1;
export const getPageRange = (totalPages: number, pageNumber: number) => {
    let upperRenderedPageButton = 0;
    let lowerRenderedPageButton = 0;

    if (isPageNumberWithinRangeOfLastPage(totalPages, pageNumber)) {
        upperRenderedPageButton = totalPages;
        lowerRenderedPageButton = totalPages - zeroIndexedPageButtons > 0 ? totalPages - zeroIndexedPageButtons : 1;
    }
    if (isPageNumberWithinRangeOfFirstPage(pageNumber)) {
        upperRenderedPageButton = totalPages <= zeroIndexedPageButtons ? totalPages : pageButtonsToRender;
        lowerRenderedPageButton = 1;
    }
    if (!isPageNumberWithinRangeOfFirstAndLastPage(upperRenderedPageButton, lowerRenderedPageButton)) {
        upperRenderedPageButton = pageNumber + 2;
        lowerRenderedPageButton = pageNumber - 2;
    }

    return {
        lowIndex: lowerRenderedPageButton,
        highIndex: upperRenderedPageButton
    };
};

const isPageNumberWithinRangeOfLastPage = (totalPages: number, actualPageNumber: number) => {
    return actualPageNumber >= totalPages - 2;
};

const isPageNumberWithinRangeOfFirstPage = (actualPageNumber: number) => {
    return actualPageNumber <= 2;
};

const isPageNumberWithinRangeOfFirstAndLastPage = (high: number, low: number) => {
    return high && low;
};

export function unpackPaging(pageResponse: PageInfo): PageInfo {
    if (!pageResponse) {
        return DefaultPage;
    }
    return {
        ...pageResponse,
        page: pageResponse.page + 1
    };
}
