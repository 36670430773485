import { Link } from 'react-router-dom';

interface NavigationBlockProps {
    title: string;
    active: boolean;
    items: { title: string; link: string; disabled?: boolean }[];
}

export const NavigationBlock = (props: NavigationBlockProps) => {
    const { active, title, items } = props;
    return (
        <li role="presentation" className={active ? 'active' : ''}>
            <Link to="#" className="dropdown-toggle" role="button" data-toggle="dropdown">
                {title}
            </Link>
            <ul className="dropdown-menu dropdown-menu-middle">
                {items.map((item) => (
                    <li key={item.title} className={item.disabled === true ? 'disabled' : ''}>
                        <Link to={item.link}>{item.title}</Link>
                    </li>
                ))}
            </ul>
        </li>
    );
};
