import { useAccountingPageState } from './AccountingPageProvider';

export const AccountingResultsSummary = () => {
    const {
        state: { searchResults, displayCriteria }
    } = useAccountingPageState();

    return (
        <>
            {displayCriteria?.accountNumber && (
                <div
                    className={'pds-util-flex'}
                    style={{ justifyContent: 'flex-end' }}
                >{`Total Account Balance: $${searchResults?.balance}`}</div>
            )}
        </>
    );
};
