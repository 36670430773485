import { createContext, Dispatch, ReactNode, useContext, useReducer } from 'react';
import { Action, defaultInitialState, AccountsReducer } from './reducer';
import { AccountsState } from './AccountsState';

const AccountsContext = createContext(undefined as never as { state: AccountsState; dispatch: Dispatch<Action> });

export function useAccountsState() {
    const context = useContext(AccountsContext);
    if (context === undefined) {
        throw new Error('useAccountsState must be used within a AccountsProvider');
    }
    return context;
}

export const AccountsProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(AccountsReducer, defaultInitialState);
    return <AccountsContext.Provider value={{ state, dispatch }}>{children}</AccountsContext.Provider>;
};
