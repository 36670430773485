import { useCallback, useEffect } from 'react';
import Select from '../layout/form/Select';
import { useAccountsState } from './AccountsProvider';
import { actions } from './reducer';
import { useAccountsSearch } from './useAccountsSearch';
import { camelCaseToTitle } from '../utils';
import SubmitButton from '../layout/form/SubmitButton';

export const AccountsSearchContainer = () => {
    const {
        state: { accountNumbers, accountAttributes, isLoading },
        dispatch
    } = useAccountsState();

    const { getAccountNumbers, getAccountAttributes, getAccounts } = useAccountsSearch();

    useEffect(() => {
        const fetchFilters = async () => {
            dispatch({ type: actions.SET, key: 'isLoading', value: true });

            await Promise.all([getAccountNumbers(), getAccountAttributes()]);

            dispatch({ type: actions.SET, key: 'isLoading', value: false });
        };
        fetchFilters();
    }, [getAccountNumbers, getAccountAttributes, dispatch]);

    const onChangeAccountNumber = useCallback(
        (e: { id: string; value: string }) => {
            dispatch({ type: actions.SET_SEARCH_CRITERIA, key: 'accountNumber', value: e.value });
        },
        [dispatch]
    );

    const accountNumberItems = accountNumbers?.map((item) => ({ code: item.value, name: item.value })) || [];

    return (
        <div
            className={'pds-util-flex'}
            style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                maxWidth: '75%',
                alignItems: 'center'
            }}
        >
            <div
                className={'pds-util-flex'}
                style={{
                    gap: '30px',
                    flexWrap: 'wrap'
                }}
            >
                {accountNumberItems.length > 0 && (
                    <div style={{ minWidth: '20%' }}>
                        <Select
                            id="accountNumber"
                            label="Account Number"
                            items={accountNumberItems}
                            showBlankOption={true}
                            onChange={onChangeAccountNumber}
                            className="input-sm"
                            required={false}
                        />
                    </div>
                )}
                {accountAttributes?.map((attribute) => {
                    return (
                        attribute.values.length > 0 && (
                            <div style={{ minWidth: '20%' }} key={attribute.code}>
                                <Select
                                    id={attribute.code}
                                    label={camelCaseToTitle(attribute.code)}
                                    items={attribute.values.map((value) => {
                                        return { code: value, name: value };
                                    })}
                                    showBlankOption={true}
                                    onChange={(event) => {
                                        dispatch({
                                            type: actions.SET_SEARCH_CRITERIA,
                                            key: `attributes.${attribute.code}`,
                                            value: event.value
                                        });
                                    }}
                                    className="input-sm"
                                    required={false}
                                />
                            </div>
                        )
                    );
                })}
            </div>
            <SubmitButton
                label={'Search'}
                color={'success'}
                loadingLabel={'...'}
                className={'btn-sm pull-right button-pull-up'}
                onClick={getAccounts}
                isLoading={isLoading}
            />
        </div>
    );
};
