import { DefaultPage } from '../../layout/pageable/utils';
import { PageInfo } from '../../layout/pageable/PageInfo';
import { ErrorsPageState } from '../ErrorsPageState';
import { ErrorEvent } from '../../../models/ErrorEvent';

export const defaultInitialState: ErrorsPageState = {
    nextPage: DefaultPage,
    currentPage: DefaultPage,
    isLoading: true,
    visibleErrorEvents: [],
    errorId: ''
};

export const enum actions {
    INITIALIZE_SEARCH_REQUEST = 'INITIALIZE_SEARCH_REQUEST',
    SEARCH_REQUEST_SUCCESS = 'SEARCH_REQUEST_SUCCESS',
    SEARCH_REQUEST_FAILED = 'SEARCH_REQUEST_FAILED',
    CHANGE_RESULTS_PER_PAGE = 'CHANGE_RESULTS_PER_PAGE',
    CHANGE_PAGE = 'CHANGE_PAGE',
    REFRESH_PAGE = 'REFRESH_PAGE'
}

export type Action =
    | { type: actions.INITIALIZE_SEARCH_REQUEST }
    | { type: actions.SEARCH_REQUEST_SUCCESS; page: PageInfo; results: Array<ErrorEvent> }
    | { type: actions.SEARCH_REQUEST_FAILED }
    | { type: actions.CHANGE_RESULTS_PER_PAGE; size: string }
    | { type: actions.CHANGE_PAGE; pageNumber: number }
    | { type: actions.REFRESH_PAGE };

export function FailedErrorsReducer(state: ErrorsPageState, action: Action): ErrorsPageState {
    switch (action.type) {
        case actions.INITIALIZE_SEARCH_REQUEST:
            return {
                ...state,
                isLoading: true,
                visibleErrorEvents: []
            };
        case actions.SEARCH_REQUEST_SUCCESS:
            return {
                ...state,
                currentPage: action.page,
                visibleErrorEvents: action.results,
                isLoading: false
            };
        case actions.SEARCH_REQUEST_FAILED:
            return {
                ...state,
                isLoading: false,
                visibleErrorEvents: []
            };
        case actions.CHANGE_RESULTS_PER_PAGE:
            return {
                ...state,
                nextPage: {
                    ...state.currentPage,
                    size: parseInt(action.size),
                    page: 1
                }
            };
        case actions.CHANGE_PAGE:
            return {
                ...state,
                nextPage: {
                    ...state.currentPage,
                    page: action.pageNumber
                }
            };
        case actions.REFRESH_PAGE:
            return {
                ...state,
                nextPage: {
                    ...state.nextPage,
                    page: 1
                }
            };
        default:
            throw new Error(`Unhandled action type: ${(action as unknown as Action).type}`);
    }
}
