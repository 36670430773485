import isEmpty from 'lodash/isEmpty';
import { useCallback } from 'react';
import SubmitButton from '../../layout/form/SubmitButton';
import Loading from '../../layout/loading/Loading';
import Pageable from '../../layout/pageable/Pageable';
import { useAccountingPageState } from './AccountingPageProvider';
import { AccountingResultsSummary } from './AccountingResultsSummary';
import { AccountingResultsTable } from './AccountingResultsTable';
import { actions } from './reducer';
import { useAccountingPageSearch } from './useAccountingPageSearch';

export const AccountingPageSearchResults = () => {
    const {
        state: { currentPage, isLoading, searchExecuted, searchResults, isGeneratingReport },
        dispatch
    } = useAccountingPageState();
    const { generateGeneralLedgerReport } = useAccountingPageSearch();

    const onChangeResultsPerPage = useCallback(
        ({ value }: { value: string }) => {
            dispatch({ type: actions.CHANGE_RESULTS_PER_PAGE, size: value });
        },
        [dispatch]
    );

    const onChangePage = useCallback(
        (value: number) => {
            dispatch({ type: actions.CHANGE_PAGE, pageNumber: value });
        },
        [dispatch]
    );

    const hasResults = !isEmpty(searchResults);
    const totalResults = hasResults ? searchResults.accounts?.length : 0;

    return (
        <>
            {isLoading && <Loading />}
            {hasResults && !isLoading && searchExecuted && (
                <>
                    <div className={'float-right'}>
                        <AccountingResultsSummary />
                    </div>
                    <SubmitButton
                        label={'Generate Report'}
                        color={'success'}
                        loadingLabel={'...'}
                        className={'btn-sm pull-left button-pull-up'}
                        onClick={generateGeneralLedgerReport}
                        isLoading={isGeneratingReport}
                    />
                    <Pageable
                        hasResults={hasResults}
                        totalResults={totalResults}
                        page={currentPage}
                        onChangePage={onChangePage}
                        showChangeResultsPerPage={true}
                        onChangeResultsPerPage={onChangeResultsPerPage}
                        showRefreshButton={true}
                        onRefreshPage={() => dispatch({ type: actions.REFRESH_PAGE })}
                    >
                        <div>
                            <AccountingResultsTable />
                        </div>
                    </Pageable>
                </>
            )}
            {!isLoading && !hasResults && searchExecuted && (
                <div className="panel-body text-center">No results were found!</div>
            )}
        </>
    );
};
