import React from 'react';
import './NotFound.css';
import MainContainer from './MainContainer';

const NotFound = () => {
    return (
        <MainContainer section="notFound">
            <div className="bg">
                <section className="svg-page error-404">
                    <div className="text-center">
                        <div>
                            <h1>We're sorry.</h1>
                            <p>We've looked everywhere and can't seem to find the page you're looking for.</p>
                        </div>
                    </div>
                </section>
            </div>
        </MainContainer>
    );
};

export default NotFound;
