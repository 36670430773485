import { useMsal } from '@azure/msal-react';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { BackendApi } from '../../../api/backendApi';
import { unpackPaging } from '../../layout/pageable/utils';
import { useAccountingPageState } from './AccountingPageProvider';
import { AccountingPageSearchResults } from './AccountingPageState';
import { actions } from './reducer';

export function useAccountingPageSearch() {
    const { instance } = useMsal();
    const {
        state: { searchCriteria, nextPage },
        dispatch
    } = useAccountingPageState();

    const getAccountSummariesResult = useCallback(async () => {
        dispatch({ type: actions.INITIALIZE_ACCOUNTING_PAGE_SEARCH_REQUEST });

        try {
            if (!searchCriteria.beginDate || !searchCriteria.endDate) {
                dispatch({
                    type: actions.NO_SEARCH_CRITERIA
                });
            } else {
                const response = await new BackendApi(instance).getAccountSummaries(nextPage, searchCriteria);
                dispatch({
                    type: actions.SEARCH_REQUEST_SUCCESS,
                    page: unpackPaging(response.page),
                    results:
                        response?.data?.length > 0
                            ? {
                                  balance: response.totalAccountBalance,
                                  accounts: response.data
                              }
                            : ({} as AccountingPageSearchResults)
                });
            }
        } catch (error) {
            console.error('failed to load search results: ', error);
            dispatch({ type: actions.SEARCH_REQUEST_FAILED });
            toast.error('Failed to load search results!', {
                toastId: 'failedAccountSummarySearch',
                autoClose: 5000
            });
        }
    }, [nextPage, dispatch, searchCriteria]);

    const getAccountNumbersResult = useCallback(async () => {
        dispatch({ type: actions.INITIALIZE_ACCOUNT_NUMBERS_SEARCH_REQUEST });

        try {
            const response = await new BackendApi(instance).getAccountNumbers();
            dispatch({
                type: actions.SEARCH_ACCOUNT_NUMBERS_REQUEST_SUCCESS,
                results: response.data || []
            });
        } catch (error) {
            console.error('failed to load account number search results: ', error);
            dispatch({ type: actions.SEARCH_REQUEST_FAILED });
            toast.error('Failed to load account numbers', {
                toastId: 'failedAccountNumberSearch',
                autoClose: 5000
            });
        }
    }, [dispatch]);

    const generateGeneralLedgerReport = useCallback(async () => {
        try {
            dispatch({
                type: actions.INITIALIZE_GENERATE_REPORT_REQUEST
            });
            await new BackendApi(instance).generateGeneralLedgerReport(
                searchCriteria as unknown as { [key: string]: string }
            );
            dispatch({
                type: actions.REPORT_GENERATION_SUCCESS
            });
            toast.success(
                'Request to generate report successfully sent! Please monitor your email inbox for download link',
                {
                    toastId: 'successfulReportGeneration'
                }
            );
        } catch (e) {
            console.error('failed to generate report request', e);
            dispatch({ type: actions.REPORT_GENERATION_FAILED });
            toast.error('Failed to Generate Report, Please try again or reach out for assistance', {
                toastId: 'failedReportGeneration',
                autoClose: 5000
            });
        }
    }, [dispatch, searchCriteria]);

    return { getAccountSummariesResult, getAccountNumbersResult, generateGeneralLedgerReport };
}
