import MainContainer from '../layout/container/MainContainer';

export function LandingPage() {
    return (
        <MainContainer>
            <div className="title">
                <span>Welcome to SC Accounting</span>
            </div>
        </MainContainer>
    );
}
