import { DefaultPage } from '../layout/pageable/utils';
import { AccountsState } from './AccountsState';

export const defaultInitialState: AccountsState = {
    nextPage: DefaultPage,
    currentPage: DefaultPage,
    isLoading: false,
    searchExecuted: false,
    pageLoadNeeded: false,
    searchCriteria: {}
};

export const enum actions {
    SET = 'SET',
    SET_SEARCH_CRITERIA = 'SET_SEARCH_CRITERIA',
    CHANGE_RESULTS_PER_PAGE = 'CHANGE_RESULTS_PER_PAGE',
    CHANGE_PAGE = 'CHANGE_PAGE',
    REFRESH_PAGE = 'REFRESH_PAGE'
}

export type Action =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | { type: actions.SET; key: string; value: any }
    | { type: actions.SET_SEARCH_CRITERIA; key: string; value: string }
    | { type: actions.CHANGE_RESULTS_PER_PAGE; size: string }
    | { type: actions.CHANGE_PAGE; page: number }
    | { type: actions.REFRESH_PAGE };

export function AccountsReducer(state: AccountsState, action: Action): AccountsState {
    switch (action.type) {
        case actions.SET:
            return {
                ...state,
                [action.key]: action.value
            };
        case actions.SET_SEARCH_CRITERIA:
            return {
                ...state,
                nextPage: DefaultPage,
                currentPage: DefaultPage,
                accounts: [],
                searchExecuted: false,
                searchCriteria: {
                    ...state.searchCriteria,
                    [action.key]: action.value
                }
            };
        case actions.CHANGE_RESULTS_PER_PAGE:
            return {
                ...state,
                pageLoadNeeded: true,
                nextPage: {
                    ...state.currentPage,
                    size: parseInt(action.size),
                    page: 1
                }
            };
        case actions.CHANGE_PAGE:
            return {
                ...state,
                pageLoadNeeded: true,
                nextPage: {
                    ...state.currentPage,
                    page: action.page
                }
            };
        case actions.REFRESH_PAGE:
            return {
                ...state,
                pageLoadNeeded: true,
                nextPage: {
                    ...state.nextPage,
                    page: 1
                }
            };
        default:
            throw new Error(`Unhandled action type: ${(action as unknown as Action).type}`);
    }
}
