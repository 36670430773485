import { useMsal } from '@azure/msal-react';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { BackendApi } from '../../api/backendApi';
import { useDownloadReportState } from './DownloadReportProvider';
import { actions } from './reducer';
import { AxiosError } from 'axios';

export function useDownloadReportSearch() {
    const { instance } = useMsal();
    const { dispatch } = useDownloadReportState();

    const getReportFile = useCallback(
        async (id: string) => {
            dispatch({ type: actions.INITIALIZE_DOWNLOAD_REPORT_REQUEST });
            try {
                const { url } = await new BackendApi(instance).getReportDownloadUrl(id);
                dispatch({ type: actions.DOWNLOAD_REPORT_STARTED });
                window.open(url, '_self');
                dispatch({ type: actions.DOWNLOAD_REPORT_SUCCESS });
            } catch (error) {
                const axiosError = error as AxiosError;
                const response = (axiosError.response?.data as { message: string }) || { message: 'Unknown Error.' };

                console.error('failed to download report: ', error);
                dispatch({ type: actions.DOWNLOAD_REPORT_FAILED });
                toast.error(response.message, {
                    toastId: 'failedDownloadReport',
                    autoClose: 5000
                });
            }
        },
        [dispatch]
    );

    return { getReportFile };
}
