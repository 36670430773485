import * as PropTypes from 'prop-types';
import Collapsible, { CollapsibleContent, CollapsibleDetail, CollapsibleSummary } from '@pds-react/collapsible';
import { Account } from 'shared/src/domain/Account';
import { camelCaseToTitle, formatDatetimeForDisplay } from '../utils';

const contentStyle = { paddingBottom: '10px' };

const AccountCollapsible = ({ account }: { account: Account }) => {
    const formattedBuiltOn = formatDatetimeForDisplay(account?.buildTimestamp);

    return (
        <Collapsible>
            <CollapsibleDetail>
                <CollapsibleSummary>Name: {account.name}</CollapsibleSummary>
                <CollapsibleContent>
                    <div
                        className={'pds-util-flex'}
                        style={{
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            columnGap: '30px',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div style={contentStyle}>
                            <b> Account Number: </b> {account.accountNumber}
                        </div>
                        <div style={contentStyle}>
                            <b> Type: </b> {account.typeText}
                        </div>
                        <div style={contentStyle}>
                            <b> Multiplier: </b> {account?.multiplier}
                        </div>
                        <div style={contentStyle}>
                            <b> Built By: </b> {account?.buildById}
                        </div>
                        <div style={{ ...contentStyle, maxWidth: '15%' }}>
                            <b> Built On: </b> {formattedBuiltOn}
                        </div>
                        {account?.description && (
                            <div style={{ ...contentStyle, maxWidth: '25%' }}>
                                <b> Description: </b> {account.description}
                            </div>
                        )}
                    </div>

                    <div style={{ ...contentStyle, textAlign: 'center', paddingTop: '50px', borderBottom: 'inherit' }}>
                        <h3>
                            <b>Attributes</b>
                        </h3>
                    </div>

                    <div
                        className={'pds-util-flex'}
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            flexWrap: 'wrap',
                            columnGap: '30px',
                            paddingTop: '30px'
                        }}
                    >
                        {account?.attributes?.map((attribute) => {
                            return (
                                <div style={contentStyle} key={attribute.code}>
                                    <b> {camelCaseToTitle(attribute?.code)}: </b> {attribute?.value}
                                </div>
                            );
                        })}
                    </div>
                </CollapsibleContent>
            </CollapsibleDetail>
        </Collapsible>
    );
};

AccountCollapsible.propTypes = {
    account: PropTypes.object.isRequired
};

export default AccountCollapsible;
