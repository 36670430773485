import React from 'react';
import { Link } from 'react-router-dom';
import * as PropTypes from 'prop-types';

interface NavigationLinkProps {
    title: string;
    link: string;
    active: boolean;
}

export const NavigationLink = (props: NavigationLinkProps) => {
    const { title, link, active } = props;

    return (
        <li role="presentation" className={active ? 'active' : ''}>
            <Link to={link}>{title}</Link>
        </li>
    );
};

NavigationLink.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    active: PropTypes.bool
};

NavigationLink.defaultProps = {
    active: false
};
