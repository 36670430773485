import MainContainer from '../layout/container/MainContainer';
import { AccountsContainer } from './AccountsContainer';
import { AccountsProvider } from './AccountsProvider';

export function AccountsPage() {
    return (
        <MainContainer section="mappings" secondarySection="accounts">
            <AccountsProvider>
                <AccountsContainer />
            </AccountsProvider>
        </MainContainer>
    );
}
