import { createContext, Dispatch, ReactNode, useContext, useReducer } from 'react';
import { Action, defaultInitialState, ProductMappingsReducer } from './reducer';
import { ProductMappingsState } from './ProductMappingsState';

const ProductMappingsContext = createContext(
    undefined as never as { state: ProductMappingsState; dispatch: Dispatch<Action> }
);

export function useProductMappingsState() {
    const context = useContext(ProductMappingsContext);
    if (context === undefined) {
        throw new Error('useProductMappingsState must be used within a ProductMappingsProvider');
    }
    return context;
}

export const ProductMappingsProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(ProductMappingsReducer, defaultInitialState);
    return <ProductMappingsContext.Provider value={{ state, dispatch }}>{children}</ProductMappingsContext.Provider>;
};
