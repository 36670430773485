import { Col, Container, Row } from '@pds-react/grid';
import { formatDateForDisplay, formatDatetimeForDisplay } from '../utils';
import { useProductMappingsState } from './ProductMappingsProvider';

export const ProductMappingResultsHeader = () => {
    const {
        state: { productMapping }
    } = useProductMappingsState();

    const formattedBuiltOn = formatDatetimeForDisplay(productMapping?.buildTimestamp);
    const formattedActiveDate = formatDateForDisplay(productMapping?.activeDate);
    const formattedInactiveDate = formatDateForDisplay(productMapping?.inactiveDate);

    return (
        <Container>
            <Row>
                <Col offset={1} span={5}>
                    <dt>Product:</dt>
                    <dd>{productMapping?.productCode}</dd>
                </Col>
                <Col span={5}>
                    <dt>Built On:</dt>
                    <dd>{formattedBuiltOn}</dd>
                </Col>
            </Row>
            <Row>
                <Col offset={1} span={5}>
                    <dt>Built By:</dt>
                    <dd>{productMapping?.buildById}</dd>
                </Col>
                <Col span={5}>
                    <dt>Active Period:</dt>
                    <dd>{`${formattedActiveDate} - ${formattedInactiveDate}`}</dd>
                </Col>
            </Row>
        </Container>
    );
};
