import * as PropTypes from 'prop-types';
import { formatDatetimeForDisplay } from '../../utils';
import { ErrorEvent } from '../../../models/ErrorEvent';
import FailedErrorEventModal from '../../modal/FailedErrorEventModal';
import ResourcePayloadModal from '../../modal/ResourcePayloadModal';

export const mapRecycleCodeToTimeMap = new Map<string, string>([
    ['1', '1/4'],
    ['2', '12'],
    ['3', '24']
]);

const FailedErrorEvent = (props: { errorEvent: ErrorEvent }) => {
    const { errorEvent } = props;

    const {
        _id,
        errorText,
        errorMessage,
        source,
        sourceEventId,
        errorCategory,
        lastUpdatedTimestamp,
        buildTimestamp,
        recycleFrequencyCode,
        recycleEvent
    } = errorEvent;

    const formattedEventReceivedDate = formatDatetimeForDisplay(buildTimestamp);
    const formattedLastRetryDate = formatDatetimeForDisplay(lastUpdatedTimestamp);
    const recycleTime = mapRecycleCodeToTimeMap.get(recycleFrequencyCode);

    return (
        <>
            <FailedErrorEventModal
                _id={_id}
                recycleEvent={recycleEvent}
                source={source}
                errorText={errorText}
                errorMessage={errorMessage}
            />
            <ResourcePayloadModal _id={_id} payload={errorEvent} title={errorText} />
            <tr>
                <td>
                    <a href="#" data-target={'.view-error' + _id} data-toggle={'modal'} title="Click for Error Details">
                        {errorText}
                    </a>
                    <span
                        style={{
                            paddingRight: '6px',
                            paddingLeft: '6px'
                        }}
                    >
                        <a
                            href="#"
                            data-target={'.view-payload' + _id}
                            data-toggle={'modal'}
                            title="Click for Error Details"
                        >
                            <i className="fa fa-info-circle help-tip"></i>
                        </a>
                    </span>
                </td>
                <td>{sourceEventId}</td>
                <td>{errorCategory}</td>
                <td>{formattedEventReceivedDate}</td>
                <td>{formattedLastRetryDate}</td>
                <td>{recycleTime}</td>
                <td></td>
            </tr>
        </>
    );
};

FailedErrorEvent.propTypes = {
    errorEvent: PropTypes.object.isRequired
};

export default FailedErrorEvent;
