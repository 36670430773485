import * as PropTypes from 'prop-types';
import Collapsible, { CollapsibleContent, CollapsibleDetail, CollapsibleSummary } from '@pds-react/collapsible';
import { Account } from 'shared/src/domain/Account';
import { camelCaseToTitle } from '../utils';

const contentStyle = { paddingBottom: '5px' };

const AccountCollapsible = ({ account }: { account: Account }) => {
    return (
        <Collapsible>
            <CollapsibleDetail>
                <CollapsibleSummary>Name: {account.name}</CollapsibleSummary>
                <CollapsibleContent>
                    <div style={contentStyle}>
                        <b> Account Number: </b> {account.accountNumber}
                    </div>
                    <div style={contentStyle}>
                        <b> Type: </b> {account.typeText}
                    </div>
                    <div style={contentStyle}>
                        <b> Multiplier: </b> {account?.multiplier}
                    </div>
                    {account?.attributes?.map((attribute) => {
                        return (
                            <div style={contentStyle} key={attribute.code}>
                                <b> {camelCaseToTitle(attribute?.code)}: </b> {attribute?.value}
                            </div>
                        );
                    })}
                </CollapsibleContent>
            </CollapsibleDetail>
        </Collapsible>
    );
};

AccountCollapsible.propTypes = {
    account: PropTypes.object.isRequired
};

export default AccountCollapsible;
