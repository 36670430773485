import { useEffect } from 'react';
import Select from '../../layout/form/Select';
import { useProductMappingsState } from '../ProductMappingsProvider';
import { actions } from '../reducer';
import { useProductMappingsSearch } from './useProductMappingsSearch';
import SubmitButton from '../../layout/form/SubmitButton';
import { linesOfBusiness } from '../../constants';

export const SearchContainer = () => {
    const {
        state: { productCodes, selectedLineOfBusiness, isLoading, selectedProductCode },
        dispatch
    } = useProductMappingsState();

    const { getProductCodes, getProductMapping } = useProductMappingsSearch();

    useEffect(() => {
        if (selectedLineOfBusiness && !productCodes[`${selectedLineOfBusiness}`]) getProductCodes();
    }, [selectedLineOfBusiness, getProductCodes]);

    const productCodeItems =
        productCodes[`${selectedLineOfBusiness}`]
            ?.map((productCode) => ({
                code: productCode.code,
                name: `${productCode.code} (${productCode.name})`
            }))
            .sort((a, b) => a.code.localeCompare(b.code)) || [];

    return (
        <div
            className={'pds-util-flex'}
            style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                alignContent: 'start',
                maxWidth: '75%'
            }}
        >
            <div style={{ minWidth: '20%' }}>
                <Select
                    label="Line Of Business"
                    items={linesOfBusiness}
                    id="linesOfBusiness"
                    onChange={(event) => {
                        dispatch({
                            type: actions.SET,
                            key: 'selectedLineOfBusiness',
                            value: event.value
                        });
                        dispatch({
                            type: actions.SET,
                            key: 'selectedProductCode',
                            value: ''
                        });
                    }}
                    required={true}
                />
            </div>

            <div style={{ minWidth: '40%' }}>
                <Select
                    label="Product Code"
                    items={productCodeItems}
                    id="productCode"
                    onChange={(event) => {
                        dispatch({
                            type: actions.SET,
                            key: 'selectedProductCode',
                            value: event.value
                        });
                    }}
                    disabled={!(selectedLineOfBusiness && productCodeItems.length > 0)}
                    required={true}
                />
            </div>

            <SubmitButton
                label={'Search'}
                color={'success'}
                loadingLabel={'...'}
                className={'btn-sm pull-right button-pull-up'}
                onClick={getProductMapping}
                isLoading={isLoading}
                disabled={!(selectedLineOfBusiness && selectedProductCode)}
            />
        </div>
    );
};
