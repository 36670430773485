import { useNavigate } from 'react-router-dom';
import StepNavigation from '../../layout/form/StepNavigation';
import StepWrapper from '../../layout/form/StepWrapper';
import SetAccountInfo from './SetAccountInfo';

export const AccountsBuildContainer = () => {
    const steps = [
        { href: '#accountInfo', name: 'Account Info', stepId: 'setAccountInfo', number: 1 },
        { href: '#accountAttributes', name: 'Account Attributes', stepId: 'setAccountAttributes', number: 2 },
        { href: '#confirm', name: 'Confirm', stepId: 'setConfirmBuild', number: 3 }
    ];

    // this should be coming from state instead of being hardcoded; it will be defaulted
    const stateStep = 'setAccountInfo';

    const navigate = useNavigate();
    const cancelAction = () => {
        navigate('/mappings/accounts');
    };

    const backAction = () => {};
    const nextAction = () => {};
    const cancelLabel = 'Cancel';
    const showBackButton = false;
    const backLabel = 'Previous';
    const nextLabel = 'Set Account Attributes';
    const isProgressing = false;
    const isLockStepOnLoading = false;
    const isNextDisabled = true;
    const stepComponent = <SetAccountInfo />;

    return (
        <>
            <StepNavigation currentStep={stateStep} steps={steps} />

            <div className="row util-padding-bottom-20">
                <div className="col-lg-12">
                    <StepWrapper
                        onCancel={cancelAction}
                        onBack={backAction}
                        onNext={nextAction}
                        cancelLabel={cancelLabel}
                        showBackButton={showBackButton}
                        backLabel={backLabel}
                        nextLabel={nextLabel}
                        isLoading={isProgressing}
                        isLockStepOnLoading={isLockStepOnLoading}
                        isNextDisabled={isNextDisabled}
                    >
                        {stepComponent}
                    </StepWrapper>
                </div>
            </div>
        </>
    );
};
