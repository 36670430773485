import * as PropTypes from 'prop-types';
import { AccountSummary } from 'shared/src/domain/Account';

const AccountingTableLineItem = (props: { dynamicHeaders: string[]; accountSummary: AccountSummary }) => {
    const { accountSummary, dynamicHeaders } = props;

    const { account, total } = accountSummary;

    return (
        <tr>
            <td>{account.name}</td>
            <td>{account.accountNumber}</td>
            <td>{account.typeText}</td>
            {dynamicHeaders.map((header, i) => (
                <td key={i}>{account.attributes.find((attribute) => attribute.code === header)?.value || 'N/A'}</td>
            ))}
            <td>{`$${total}`}</td>
        </tr>
    );
};

AccountingTableLineItem.propTypes = {
    accountSummary: PropTypes.object.isRequired,
    dynamicHeaders: PropTypes.array.isRequired
};

export default AccountingTableLineItem;
