import { createContext, Dispatch, ReactNode, useContext, useReducer } from 'react';
import { Action, defaultInitialState, AccountingPageReducer } from './reducer';
import { AccountingPageState } from './AccountingPageState';

const AccountingPageContext = createContext(
    undefined as never as { state: AccountingPageState; dispatch: Dispatch<Action> }
);

export function useAccountingPageState() {
    const context = useContext(AccountingPageContext);
    if (context === undefined) {
        throw new Error('useAccountingPageState must be used within a AccountingPageProvider');
    }
    return context;
}

export const AccountingPageProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(AccountingPageReducer, defaultInitialState);
    return <AccountingPageContext.Provider value={{ state, dispatch }}>{children}</AccountingPageContext.Provider>;
};
