import { camelCaseToTitle } from '../utils';
import ProductMappingRulesTableLineItem from './ProductMappingRulesTableLineItem';
import { useProductMappingsState } from './ProductMappingsProvider';

export const ProductMappingRulesTable = () => {
    const {
        state: { productMapping }
    } = useProductMappingsState();

    const dynamicColumnHeaders = Array.from(
        new Set(
            productMapping?.rules
                ?.flatMap((rule) => rule?.attributes?.map((attribute) => attribute?.code))
                ?.filter(Boolean)
        )
    );
    const formattedDynamicColumnHeaders = dynamicColumnHeaders.map(camelCaseToTitle);

    return (
        <div className="table-responsive">
            <table
                id="productMappingRuleResults"
                className="table table-hover no-footer"
                role="grid"
                aria-describedby="productMappingRuleResults_info"
            >
                <thead className="text-nowrap">
                    <tr>
                        {formattedDynamicColumnHeaders.map((header, i) => (
                            <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="productMappingRuleResults"
                                aria-sort="descending"
                                key={i}
                            >
                                {header}
                            </th>
                        ))}
                        <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="productMappingRuleResults"
                            aria-sort="descending"
                        >
                            Accounts
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {productMapping?.rules?.map((result, i) => {
                        return (
                            <ProductMappingRulesTableLineItem
                                key={i}
                                dynamicHeaders={dynamicColumnHeaders}
                                rule={result}
                            />
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
