import { ProductMappingSearchResults } from './ProductMappingSearchResults';
import { SearchContainer } from './view/SearchContainer';

const ProductMappingsContainer = () => {
    return (
        <>
            <SearchContainer />
            <ProductMappingSearchResults />
        </>
    );
};

export default ProductMappingsContainer;
