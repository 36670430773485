import MainContainer from '../layout/container/MainContainer';
import { ProductMappingsProvider } from './ProductMappingsProvider';
import ProductMappingsContainer from './ProductMappingsContainer';

export function ProductMappingsPage() {
    return (
        <MainContainer section="mappings" secondarySection="products">
            <ProductMappingsProvider>
                <ProductMappingsContainer />
            </ProductMappingsProvider>
        </MainContainer>
    );
}
