import * as PropTypes from 'prop-types';
import { ReactNode, useCallback } from 'react';
import Loading from '../loading/Loading';
import SubmitButton from './SubmitButton';

const StepWrapper = (props: {
    children?: ReactNode;
    onCancel: (args?: object) => void;
    cancelLabel: string;
    showBackButton: boolean;
    onBack: (args?: object) => void;
    backLabel: string;
    onNext: (args?: object) => void;
    nextLabel: string;
    isLoading: boolean;
    isLockStepOnLoading: boolean;
    isNextDisabled: boolean;
}) => {
    const {
        children,
        onCancel,
        cancelLabel,
        showBackButton,
        onBack,
        backLabel,
        onNext,
        nextLabel,
        isLoading,
        isLockStepOnLoading,
        isNextDisabled
    } = props;

    const cancel = useCallback(() => {
        onCancel();
    }, [onCancel]);

    const back = useCallback(() => {
        onBack();
    }, [onBack]);

    const next = useCallback(() => {
        onNext();
    }, [onNext]);

    return (
        <div>
            {isLoading && !isLockStepOnLoading ? (
                <Loading />
            ) : (
                <div className="row">
                    <div className="col-lg-12">{children}</div>
                </div>
            )}

            <div className="row util-padding-top-10">
                <div className="col-xs-4">
                    {isLoading || (
                        <SubmitButton
                            type="button"
                            label={cancelLabel}
                            color="link"
                            className="step-button"
                            onClick={cancel}
                            isLoading={isLoading}
                        />
                    )}
                </div>
                <div className="col-xs-8 text-right">
                    {showBackButton && !isLoading && (
                        <SubmitButton
                            type="button"
                            label={backLabel}
                            color="link"
                            className="step-button"
                            onClick={back}
                            isLoading={isLoading}
                        />
                    )}

                    <SubmitButton
                        type="button"
                        label={nextLabel}
                        color="primary"
                        className="step-button"
                        onClick={next}
                        isLoading={isLoading || isNextDisabled}
                        loadingLabel={isLoading ? '...' : isNextDisabled ? nextLabel : ''}
                    />
                </div>
            </div>
        </div>
    );
};

StepWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    onCancel: PropTypes.func.isRequired,
    cancelLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    showBackButton: PropTypes.bool,
    onBack: PropTypes.func.isRequired,
    backLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onNext: PropTypes.func.isRequired,
    nextLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isLoading: PropTypes.bool,
    isLockStepOnLoading: PropTypes.bool,
    isNextDisabled: PropTypes.bool
};

StepWrapper.defaultProps = {
    cancelLabel: 'Cancel',
    backLabel: 'Previous',
    nextLabel: 'Next',
    showBackButton: false,
    isLoading: false,
    isLockStepOnLoading: false,
    isNextDisabled: false
};

export default StepWrapper;
