import map from 'lodash/map';
import FailedErrorEvent from './FailedErrorEvent';
import { useFailedErrorsState } from './FailedErrorsProvider';

export const FailedErrorsTable = () => {
    const {
        state: { visibleErrorEvents }
    } = useFailedErrorsState();

    return (
        <div className="table-responsive">
            <table
                id="errorEventTable"
                className="table table-hover no-footer"
                role="grid"
                aria-describedby="errorEventTable_info"
            >
                <thead className="text-nowrap">
                    <tr>
                        <th className="sorting" tabIndex={0} aria-controls="errorEventTable" aria-sort="descending">
                            Title
                        </th>
                        <th className="sorting" tabIndex={0} aria-controls="errorEventTable" aria-sort="descending">
                            Source Event Id
                        </th>
                        <th className="sorting" tabIndex={0} aria-controls="errorEventTable" aria-sort="descending">
                            Event Type
                        </th>
                        <th className="sorting" tabIndex={0} aria-controls="errorEventTable" aria-sort="descending">
                            Error Received Date
                        </th>
                        <th className="sorting" tabIndex={0} aria-controls="errorEventTable" aria-sort="descending">
                            Last Retry Date
                        </th>
                        <th className="sorting" tabIndex={0} aria-controls="errorEventTable" aria-sort="descending">
                            Recycle
                            <br />
                            Interval (hrs.)
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {map(visibleErrorEvents, (result, i) => (
                        <FailedErrorEvent key={i} errorEvent={result} />
                    ))}
                </tbody>
            </table>
        </div>
    );
};
