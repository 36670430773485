import findIndex from 'lodash/findIndex';
import orderBy from 'lodash/orderBy';
import * as PropTypes from 'prop-types';
import { ChangeEvent, useCallback } from 'react';
import FormGroup from './FormGroup';
import Label from './Label';

const Select = (props: {
    items: Array<{
        code: number | string;
        name: string;
        disabled?: boolean;
    }>;
    showBlankOption: boolean;
    additionalItemIfNotPresent?: {
        code: number | string;
        name: string;
    };
    id: string;
    name?: string;
    label: string;
    className: string;
    defaultValue: string | Array<string>;
    disabled?: boolean;
    required?: boolean;
    multiple?: boolean;
    onChange?: (value: { id: string; value: string; event: ChangeEvent<HTMLSelectElement> }) => void;
}) => {
    const {
        items,
        showBlankOption,
        additionalItemIfNotPresent,
        id,
        name,
        label,
        className,
        defaultValue,
        disabled,
        required,
        multiple
    } = props;

    const onChangeEvent = useCallback(
        (event: ChangeEvent<HTMLSelectElement>) => {
            const { onChange } = props;
            if (onChange) {
                onChange({
                    id: event.target.id,
                    value: event.target.value,
                    event: event
                });
            }
        },
        [props]
    );

    let allItems = items;
    if (
        additionalItemIfNotPresent &&
        additionalItemIfNotPresent.code &&
        findIndex(items, ['code', additionalItemIfNotPresent.code]) < 0
    ) {
        allItems = orderBy([additionalItemIfNotPresent].concat(items), ['name']);
    }

    return (
        <FormGroup id={id}>
            <Label id={id} label={label} required={required} />
            {items === undefined || (
                <select
                    multiple={multiple}
                    id={id}
                    name={name || id}
                    className={'form-control ' + className}
                    defaultValue={defaultValue}
                    onChange={onChangeEvent}
                    disabled={disabled}
                >
                    {showBlankOption && <option value="" />}
                    {allItems.map((item) => {
                        return (
                            <option value={item.code} key={item.code} disabled={item?.disabled}>
                                {item.name}
                            </option>
                        );
                    })}
                </select>
            )}
        </FormGroup>
    );
};

Select.propTypes = {
    items: PropTypes.array,
    showBlankOption: PropTypes.bool,
    additionalItemIfNotPresent: PropTypes.object,
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object]),
    className: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    multiple: PropTypes.bool
};

Select.defaultProps = {
    showBlankOption: true,
    label: '',
    defaultValue: '',
    className: '',
    items: [],
    multiple: false
};

export default Select;
