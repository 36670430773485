import MainContainer from '../../layout/container/MainContainer';

export function DiscardedErrorsPage() {
    return (
        <MainContainer section="errorEvents" secondarySection="discardedErrors">
            <div className="title">
                <span>Discarded Errors</span>
            </div>
        </MainContainer>
    );
}
