import React, { ReactNode } from 'react';
import * as PropTypes from 'prop-types';

import Header from './Header';
import { NavigationLink } from './NavigationLink';

import './MainContainer.css';
import EnterpriseFooter from '@pds-react/enterpriseFooter';
import ErrorEventNavigation from './ErrorEventNavigation';
import TransactionsNavigation from './TransactionsNavigation';
import { useMsal } from '@azure/msal-react';
import { MappingsNavigation } from './MappingsNavigation';

interface MainContainerProps {
    children: ReactNode;
    section?: string;
    secondarySection?: string;
    headerSection?: string;
}

const MainContainer = (props: MainContainerProps) => {
    const { children, section, secondarySection, headerSection } = props;

    const { instance } = useMsal();
    instance.setActiveAccount(instance.getAllAccounts()[0]);

    const watermarkClass: string =
        window.location.hostname === 'accounting.salescompadmin.principal.com' ? '' : 'nonprod-class';

    return (
        <div className={watermarkClass}>
            {headerSection && (
                <>
                    <Header>
                        <NavigationLink title="Transactions" link="/transactions" />
                        <NavigationLink title="Mappings" link="/mappings" />
                        <NavigationLink title="Errors" link="/error-events" />
                    </Header>

                    <>{section === 'errorEvents' && <ErrorEventNavigation secondarySection={secondarySection} />}</>
                    <>{section === 'transactions' && <TransactionsNavigation secondarySection={secondarySection} />}</>
                    <>{section === 'mappings' && <MappingsNavigation secondarySection={secondarySection} />}</>
                </>
            )}

            <div
                className="container-fluid"
                style={{
                    position: 'relative',
                    paddingBottom: '250px',
                    marginBottom: '20px'
                }}
            >
                <div className="row util-padding-top-20">
                    <div className="col-xl-10 col-xl-offset-1 app-main-container">{children}</div>
                </div>
            </div>

            <div
                style={{
                    position: 'absolute',
                    bottom: '0',
                    height: '250px',
                    width: '100%'
                }}
            >
                <EnterpriseFooter contactUsUrl="https://www.principal.com/contact-us" variant="light" />
            </div>
        </div>
    );
};

MainContainer.propTypes = {
    children: PropTypes.node.isRequired,
    section: PropTypes.string,
    headerSection: PropTypes.bool,
    secondarySection: PropTypes.string
};

MainContainer.defaultProps = {
    headerSection: true
};

export default MainContainer;
