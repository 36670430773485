import { IPublicClientApplication } from '@azure/msal-browser';
import { AccountAttributeDomainResponseModel } from 'shared/src/domain/AccountAttributeDomainResponseModel';
import { AccountSummaryResponseModel } from 'shared/src/domain/AccountSummaryResponseModel';
import { DownloadReportResponseModel } from 'shared/src/domain/DownloadReportResponseModel';
import { ProductAccountMapping } from 'shared/src/domain/ProductAccountMapping';
import { PageInfo } from '../app/layout/pageable/PageInfo';
import { ErrorEvent } from '../models/ErrorEvent';
import { get } from './get';
import { post } from './post';
import { Product } from '../models/Product';
import { Account } from 'shared/src/domain/Account';
import { AccountAttributeValues } from 'shared/src/domain/AccountAttributeValues';
import { PaginatedResult } from 'shared/src/domain/PaginatedResult';

export const ApiUrl = window.location.hostname === 'localhost' ? 'https://localhost:8079/api' : '/api';

export class BackendApi {
    constructor(private instance: IPublicClientApplication) {}

    async viewAllErrors(
        page: { size: number; page: number },
        errorStatus: string
    ): Promise<{ data: ErrorEvent[]; page: PageInfo }> {
        const pageNumber = page.page - 1;
        const query = new URLSearchParams({
            errorStatus: errorStatus,
            page: pageNumber.toString(),
            size: page.size.toString()
        }).toString();

        const url = `${ApiUrl}/errors?${query}`;
        return await get<{ data: ErrorEvent[]; page: PageInfo }>(url, this.instance);
    }

    async getAccountSummaries(
        page: { size: number; page: number },
        filterCriteria: {
            accountNumber?: string;
            linesOfBusiness?: string[];
            beginDate: string;
            endDate: string;
        }
    ): Promise<AccountSummaryResponseModel> {
        const params = {
            page: page.page - 1,
            size: page.size,
            ...filterCriteria
        };

        const url = `${ApiUrl}/life-general-ledger/reporting/accounts`;
        return await get<AccountSummaryResponseModel>(url, this.instance, params);
    }

    async getAccountNumbers(): Promise<AccountAttributeDomainResponseModel> {
        const url = `${ApiUrl}/accounts/attributes/accountNumber`;
        return await get<AccountAttributeDomainResponseModel>(url, this.instance);
    }

    async getAccountAttributeCodes(): Promise<AccountAttributeDomainResponseModel> {
        const url = `${ApiUrl}/accounts/attributes/attributes.code`;
        return await get<AccountAttributeDomainResponseModel>(url, this.instance);
    }

    async getAccountAttributeValuesBatch(body: { attributeCodes: string[] }): Promise<AccountAttributeValues[]> {
        const url = `${ApiUrl}/accounts/attributes/attributes.code/retrieve-all`;
        return await post(url, this.instance, body);
    }

    async getAccounts(filterCriteria: { [key: string]: string | number } = {}): Promise<PaginatedResult<Account>> {
        const url = `${ApiUrl}/accounts`;
        return await get(url, this.instance, filterCriteria);
    }

    async generateGeneralLedgerReport(filterCriteria: { [key: string]: string | number }): Promise<void> {
        const url = `${ApiUrl}/reports/general-ledger`;
        return await post(url, this.instance, filterCriteria);
    }

    async getReportDownloadUrl(id: string): Promise<DownloadReportResponseModel> {
        const url = `${ApiUrl}/reports/general-ledger/download-link`;
        return await get(url, this.instance, { id });
    }

    async getProductCodes(lineOfBusiness: string): Promise<Product[]> {
        const url = `${ApiUrl}/products`;
        return await get(url, this.instance, { lineOfBusiness });
    }

    async getProductMappings(
        lineOfBusiness: string,
        productCode: string,
        status?: string
    ): Promise<ProductAccountMapping[]> {
        const url = `${ApiUrl}/product-mappings`;
        return await get(url, this.instance, { lineOfBusiness, productCode, ...(status && { status }) });
    }

    async getAccountsBatch(body: { ids: string[] }): Promise<Account[]> {
        const url = `${ApiUrl}/accounts/retrieve-all`;
        return await post(url, this.instance, body);
    }
}
