import { useMsal } from '@azure/msal-react';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { BackendApi } from '../../api/backendApi';
import { useAccountsState } from './AccountsProvider';
import { actions } from './reducer';
import { unpackPaging } from '../layout/pageable/utils';

export function useAccountsSearch() {
    const { instance } = useMsal();
    const {
        state: { searchCriteria, nextPage },
        dispatch
    } = useAccountsState();

    const getAccountNumbers = useCallback(async () => {
        try {
            const response = await new BackendApi(instance).getAccountNumbers();
            dispatch({
                type: actions.SET,
                key: 'accountNumbers',
                value: response.data
            });
        } catch (error) {
            console.error('failed to load account numbers: ', error);
            toast.error('Failed to load account numbers', {
                toastId: 'FailedAccountNumberSearch',
                autoClose: 5000
            });
        }
    }, [dispatch]);

    const getAccountAttributes = useCallback(async () => {
        try {
            const backendApi = new BackendApi(instance);

            const response = await backendApi.getAccountAttributeCodes();

            const attributeCodes = response?.data?.map((attribute) => attribute?.value);
            const accountAttributes = await backendApi.getAccountAttributeValuesBatch({ attributeCodes });

            dispatch({
                type: actions.SET,
                key: 'accountAttributes',
                value: accountAttributes
            });
        } catch (error) {
            console.error('failed to load account attributes: ', error);
            toast.error('Failed to load account attributes', {
                toastId: 'FailedAccountAttributeSearch',
                autoClose: 5000
            });
        }
    }, [dispatch]);

    const getAccounts = useCallback(async () => {
        dispatch({ type: actions.SET, key: 'isLoading', value: true });
        try {
            const validSearchCriteria = Object.entries(searchCriteria)?.reduce(
                (acc, [key, value]) => (value ? { ...acc, [key]: value } : acc),
                {}
            );

            const queryParams = { page: nextPage.page - 1, size: nextPage.size, ...validSearchCriteria };
            const response = await new BackendApi(instance).getAccounts(queryParams);

            dispatch({
                type: actions.SET,
                key: 'accounts',
                value: response.data
            });
            dispatch({
                type: actions.SET,
                key: 'currentPage',
                value: unpackPaging(response.page)
            });
            dispatch({
                type: actions.SET,
                key: 'searchExecuted',
                value: true
            });
        } catch (error) {
            console.error('failed to load accounts: ', error);
            toast.error('Failed to load accounts', {
                toastId: 'FailedAccountsSearch',
                autoClose: 5000
            });
        }
        dispatch({
            type: actions.SET,
            key: 'isLoading',
            value: false
        });
    }, [dispatch, searchCriteria, nextPage]);

    return { getAccountNumbers, getAccountAttributes, getAccounts };
}
