import * as PropTypes from 'prop-types';

export interface Step {
    href: string;
    name: string;
    stepId: string;
    number: number;
}

const StepNavigation = (props: { currentStep: string; steps: Step[] }) => {
    const { currentStep, steps } = props;

    const stepPos = steps.find((elem) => elem.stepId == currentStep)?.number;

    if (!currentStep || !stepPos) {
        return false;
    }

    const disabledCss = 'horizon-step-indicator horizon-step-indicator-disabled';
    const activeCss = 'horizon-step-indicator horizon-step-indicator-active';
    const visitedCss = 'horizon-step-indicator horizon-step-indicator-previous';

    return (
        <ol className="indicator util-padding-bottom-20">
            {steps.map((step) => {
                return (
                    <li
                        aria-label={step.name}
                        className={
                            currentStep === step.stepId ? activeCss : stepPos > step.number ? visitedCss : disabledCss
                        }
                    >
                        <a href={step.href}>
                            <span className="indicator-underline">{step.name}</span>
                        </a>
                    </li>
                );
            })}
        </ol>
    );
};

StepNavigation.propTypes = {
    currentStep: PropTypes.string,
    steps: PropTypes.array
};

StepNavigation.defaultProps = {};

export default StepNavigation;
