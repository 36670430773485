import Select from '../../layout/form/Select';
import TextField from '../../layout/form/TextField';

const SetAccountInfo = () => {
    return (
        <>
            <div
                className={'pds-util-flex'}
                style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    maxWidth: '100%',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: '30px'
                }}
            >
                <div style={{ minWidth: '20%' }}>
                    <TextField id={'policyId'} label={'Policy Number'} onChange={() => {}} />
                </div>
                <div style={{ minWidth: '20%' }}>
                    <Select
                        id="accountNumber"
                        label="Account Number"
                        //items={accountNumberItems}
                        showBlankOption={true}
                        //onChange={onChangeAccountNumber}
                        required={false}
                    />
                </div>
                <div style={{ minWidth: '20%' }}>
                    <Select
                        id="accountType"
                        label="Account Type"
                        //items={accountNumberItems}
                        showBlankOption={true}
                        //onChange={onChangeAccountNumber}
                        required={false}
                    />
                </div>
                <div style={{ minWidth: '20%' }}>
                    <Select
                        id="accountMultiplier"
                        label="Account Multiplier"
                        //items={accountNumberItems}
                        showBlankOption={true}
                        //onChange={onChangeAccountNumber}
                        required={false}
                    />
                </div>
            </div>
            <div
                className={'pds-util-flex'}
                style={{
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    maxWidth: '100%',
                    alignItems: 'center',
                    flexWrap: 'wrap'
                }}
            >
                <div style={{ minWidth: '20%' }}>
                    <Select
                        id="businessUnitCode"
                        label="Business Unit Code"
                        //items={accountNumberItems}
                        showBlankOption={true}
                        //onChange={onChangeAccountNumber}
                        required={false}
                    />
                </div>
                <div style={{ minWidth: '20%' }}>
                    <Select
                        id="businessReportingUnitCode"
                        label="Business Reporting Unit Code"
                        //items={accountNumberItems}
                        showBlankOption={true}
                        //onChange={onChangeAccountNumber}
                        required={false}
                    />
                </div>
            </div>
        </>
    );
};

SetAccountInfo.propTypes = {};

SetAccountInfo.defaultProps = {};

export default SetAccountInfo;
