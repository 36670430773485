import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';

interface SubmitButtonProps {
    label?: string;
    type: 'submit' | 'button' | 'reset' | undefined;
    color?: string;
    className?: string;
    loadingLabel?: string;
    onClick: (args?: object) => void;
    isLoading: boolean;
    disabled?: boolean;
    dataToggle?: string;
    dataTarget?: string;
}

const SubmitButton = (props: SubmitButtonProps) => {
    const { label, type, color, className, isLoading, loadingLabel, dataToggle, dataTarget, disabled } = props;

    const onClick = useCallback(
        (e: object) => {
            const { onClick } = props;
            if (onClick) {
                onClick(e);
            }
        },
        [props]
    );

    return (
        <button
            type={type}
            onClick={onClick}
            disabled={isLoading || disabled}
            className={'btn btn-' + color + ' ' + className}
            data-toggle={dataToggle}
            data-target={dataTarget}
        >
            {isLoading ? loadingLabel : label}
        </button>
    );
};

SubmitButton.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    type: PropTypes.string,
    color: PropTypes.string,
    className: PropTypes.string,
    loadingLabel: PropTypes.string,
    onClick: PropTypes.func,
    isLoading: PropTypes.bool,
    dataToggle: PropTypes.string,
    dataTarget: PropTypes.string
};

SubmitButton.defaultProps = {
    loadingLabel: '',
    type: 'submit',
    color: 'primary'
};

export default SubmitButton;
