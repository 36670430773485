import React, { ReactNode, MouseEvent, useCallback } from 'react';
import * as PropTypes from 'prop-types';
import range from 'lodash/range';
import { doNothingWithEvent } from '../../utils';
import { getPageRange, ResultsPerPageOptions } from './utils';
import Select from '../form/Select';
import PageNavigationLeftButtons from './PageNavigationLeftButtons';
import PageNavigationRightButtons from './PageNavigationRightButtons';
import RefreshButton from '../form/RefreshButton';
import { PageInfo } from './PageInfo';

export type PropsType = {
    children: ReactNode;
    page: PageInfo;
    hasResults: boolean;
    totalResults: number;
    isLoading: boolean;
    showChangeResultsPerPage: boolean;
    onChangeResultsPerPage: ({ value }: { value: string }) => void;
    onChangePage: (value: number) => void;
    showRefreshButton: boolean;
    onRefreshPage: () => void;
};

const Pageable = (props: PropsType) => {
    const {
        children,
        page: currentPage,
        hasResults,
        totalResults,
        isLoading,
        showChangeResultsPerPage,
        onChangeResultsPerPage,
        onChangePage,
        showRefreshButton,
        onRefreshPage
    } = props;

    const { size, page, totalElements, totalPages } = currentPage;

    const onFirstPage = page === 1;
    const onLastPage = page === totalPages;
    const onlyOnePageExists = onFirstPage && onLastPage;
    const lookingAtResultsMessage = hasResults && size * (page - 1) + 1 + '-' + (size * (page - 1) + totalResults);
    const pageRange = getPageRange(totalPages, page);

    const goToFirstPage = useCallback(
        (event: MouseEvent) => {
            event.preventDefault();
            onChangePage(1);
        },
        [onChangePage]
    );

    const onPageBackward = useCallback(
        (event: MouseEvent) => {
            event.preventDefault();
            onChangePage(page - 1);
        },
        [onChangePage, page]
    );

    const goToLastPage = useCallback(
        (event: MouseEvent) => {
            event.preventDefault();
            onChangePage(totalPages);
        },
        [onChangePage, totalPages]
    );

    const onPageForward = useCallback(
        (event: MouseEvent) => {
            event.preventDefault();
            onChangePage(page + 1);
        },
        [onChangePage, page]
    );

    const goToPage = useCallback(
        (input: number, event: MouseEvent) => {
            event.preventDefault();
            onChangePage(input);
        },
        [onChangePage]
    );

    return (
        <div className="row">
            <div className="col-xs-12">
                <div className="panel panel-default">
                    {(hasResults || isLoading) && showChangeResultsPerPage && (
                        <div className="panel-body">
                            <span className="form-inline">
                                <strong>
                                    Show{' '}
                                    <Select
                                        id="resultsPerPage"
                                        label=""
                                        items={ResultsPerPageOptions}
                                        showBlankOption={false}
                                        defaultValue={size + ''}
                                        onChange={onChangeResultsPerPage}
                                        className="input-sm"
                                    />{' '}
                                    entries
                                </strong>
                                {showRefreshButton && (
                                    <div className="pull-right">
                                        <RefreshButton label="" isLoading={isLoading} onClick={onRefreshPage} />
                                    </div>
                                )}
                            </span>
                        </div>
                    )}

                    {children}

                    {hasResults && (
                        <div className="panel-footer">
                            <div className="row">
                                {onlyOnePageExists ? (
                                    <div className="col-xs-6 text-left">Showing all results</div>
                                ) : (
                                    <div className="col-xs-6 text-left">
                                        Showing {lookingAtResultsMessage} of {totalElements} results
                                    </div>
                                )}
                                <div className="col-xs-6 text-right">
                                    {onlyOnePageExists || (
                                        <ul className="pagination">
                                            {onFirstPage ? (
                                                <PageNavigationLeftButtons
                                                    disabled
                                                    onGoBack={doNothingWithEvent}
                                                    onGoToBeginning={doNothingWithEvent}
                                                />
                                            ) : (
                                                <PageNavigationLeftButtons
                                                    onGoBack={onPageBackward}
                                                    onGoToBeginning={goToFirstPage}
                                                />
                                            )}

                                            {pageRange.lowIndex > 1 && (
                                                <li className="disabled">
                                                    <a href="#" onClick={doNothingWithEvent}>
                                                        ...
                                                    </a>
                                                </li>
                                            )}

                                            {range(pageRange.lowIndex, pageRange.highIndex + 1).map((pageCount) => {
                                                return (
                                                    <li
                                                        key={pageCount}
                                                        className={page === pageCount ? 'active' : undefined}
                                                    >
                                                        <a href="#" onClick={goToPage.bind(null, pageCount)}>
                                                            {pageCount}
                                                        </a>
                                                    </li>
                                                );
                                            })}

                                            {pageRange.highIndex < totalPages && (
                                                <li className="disabled" onClick={doNothingWithEvent}>
                                                    <a href="#">...</a>
                                                </li>
                                            )}

                                            {onLastPage ? (
                                                <PageNavigationRightButtons
                                                    disabled
                                                    onGoForward={doNothingWithEvent}
                                                    onGoToEnd={doNothingWithEvent}
                                                />
                                            ) : (
                                                <PageNavigationRightButtons
                                                    onGoForward={onPageForward}
                                                    onGoToEnd={goToLastPage}
                                                />
                                            )}
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

Pageable.propTypes = {
    children: PropTypes.node.isRequired,
    page: PropTypes.object.isRequired,
    hasResults: PropTypes.bool,
    totalResults: PropTypes.number,
    isLoading: PropTypes.bool,
    showChangeResultsPerPage: PropTypes.bool,
    onChangeResultsPerPage: PropTypes.func,
    onChangePage: PropTypes.func.isRequired
};

Pageable.defaultProps = {
    hasResults: false,
    totalResults: 0,
    isLoading: false,
    showChangeResultsPerPage: false
};

export default Pageable;
