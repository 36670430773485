import * as PropTypes from 'prop-types';
import { Account } from 'shared/src/domain/Account';
import AccountCollapsible from './AccountCollapsible';

const AccountsModal = (props: { id: string; accounts: Account[] }) => {
    const { accounts, id } = props;
    return (
        <>
            <div
                className={'modal fade view-accounts' + id}
                tabIndex={-1}
                role="dialog"
                aria-labelledby="my-large-modal-label"
            >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h2 className="modal-title" id="static-modal-label">
                                Accounts
                            </h2>
                        </div>
                        <div className="modal-body">
                            <div>
                                {accounts.map((account) => (
                                    <AccountCollapsible account={account} key={account._id} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

AccountsModal.propTypes = {
    id: PropTypes.string.isRequired,
    accounts: PropTypes.array.isRequired
};

export default AccountsModal;
