import React from 'react';
import * as PropTypes from 'prop-types';
import { NavigationLink } from './NavigationLink';
import { SecondaryNavigation } from './SecondaryNavigation';

interface ErrorEventNavigationProps {
    secondarySection?: string;
}

const ErrorEventNavigation = (props: ErrorEventNavigationProps) => {
    const { secondarySection } = props;

    return (
        <SecondaryNavigation>
            <NavigationLink
                title="Failed Errors"
                link="/error-events/failed-errors"
                active={secondarySection === 'failedErrors'}
            />
            <NavigationLink
                title="Discarded Errors"
                link="/error-events/discarded-errors"
                active={secondarySection === 'discardedErrors'}
            />
        </SecondaryNavigation>
    );
};

ErrorEventNavigation.propTypes = {
    secondarySection: PropTypes.string.isRequired
};

export default ErrorEventNavigation;
