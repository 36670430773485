import { createContext, Dispatch, ReactNode, useContext, useReducer } from 'react';
import { Action, defaultInitialState, DownloadReportReducer } from './reducer';
import { DownloadReportState } from './DownloadReportState';

const DownloadReportContext = createContext(
    undefined as never as { state: DownloadReportState; dispatch: Dispatch<Action> }
);

export function useDownloadReportState() {
    const context = useContext(DownloadReportContext);
    if (context === undefined) {
        throw new Error('useDownloadReportState must be used within a Provider: DownloadReportContext');
    }
    return context;
}

export const DownloadReportProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(DownloadReportReducer, defaultInitialState);
    return <DownloadReportContext.Provider value={{ state, dispatch }}>{children}</DownloadReportContext.Provider>;
};
