import MainContainer from '../layout/container/MainContainer';
import { DownloadReportProvider } from './DownloadReportProvider';
import { DownloadReportContainer } from './DownloadReportContainer';

export function DownloadReportPage() {
    return (
        <MainContainer>
            <DownloadReportProvider>
                <DownloadReportContainer />
            </DownloadReportProvider>
        </MainContainer>
    );
}
