import React, { MouseEventHandler } from 'react';
import * as PropTypes from 'prop-types';

const RefreshButton = (props: {
    type: 'submit' | 'button' | 'reset' | undefined;
    isLoading: boolean;
    onClick: MouseEventHandler<HTMLButtonElement> | undefined;
}) => {
    const { type, isLoading, onClick } = props;

    return (
        <button type={type} onClick={onClick} disabled={isLoading} className={'btn btn-default bt-sm'}>
            <i className="fa fa-refresh" />
        </button>
    );
};

RefreshButton.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    type: PropTypes.string,
    loadingLabel: PropTypes.string,
    onClick: PropTypes.func,
    isLoading: PropTypes.bool
};

RefreshButton.defaultProps = {
    loadingLabel: '',
    type: 'submit'
};

export default RefreshButton;
