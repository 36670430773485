import { AccountAttributeDomain } from 'shared/src/domain/AccountAttributeDomainResponseModel';
import { PageInfo } from '../../layout/pageable/PageInfo';
import { DefaultPage } from '../../layout/pageable/utils';
import { AccountingPageSearchCriteria, AccountingPageSearchResults, AccountingPageState } from './AccountingPageState';
import { ALL_ACTIVE_BUSINESS_UNITS } from '../../constants';

// Default start date to the 1st day of current month
const defaultBeginDate = new Date();
defaultBeginDate.setDate(1);
const defaultEndDate = new Date();

const defaultDisplayCriteria = {
    accountNumber: undefined,
    beginDate: defaultBeginDate.toISOString(),
    endDate: defaultEndDate.toISOString(),
    linesOfBusiness: ALL_ACTIVE_BUSINESS_UNITS
};

const defaultSearchCriteria: AccountingPageSearchCriteria = {
    accountNumber: undefined,
    beginDate: new Date(defaultBeginDate.getTime() - defaultBeginDate.getTimezoneOffset() * 60 * 1000)
        .toISOString()
        .split('T')[0],
    endDate: new Date(defaultEndDate.getTime() - defaultEndDate.getTimezoneOffset() * 60 * 1000)
        .toISOString()
        .split('T')[0],
    linesOfBusiness: ALL_ACTIVE_BUSINESS_UNITS
};

export const defaultInitialState: AccountingPageState = {
    nextPage: DefaultPage,
    currentPage: DefaultPage,
    isLoading: true,
    searchExecuted: false,
    searchCriteria: defaultSearchCriteria,
    displayCriteria: defaultDisplayCriteria,
    isGeneratingReport: false
};

export const enum actions {
    NO_SEARCH_CRITERIA = 'NO_SEARCH_CRITERIA',
    INITIALIZE_ACCOUNTING_PAGE_SEARCH_REQUEST = 'INITIALIZE_ACCOUNTING_PAGE_SEARCH_REQUEST',
    INITIALIZE_ACCOUNT_NUMBERS_SEARCH_REQUEST = 'INITIALIZE_ACCOUNT_NUMBERS_SEARCH_REQUEST',
    SEARCH_REQUEST_SUCCESS = 'SEARCH_REQUEST_SUCCESS',
    SEARCH_ACCOUNT_NUMBERS_REQUEST_SUCCESS = 'SEARCH_ACCOUNT_NUMBERS_REQUEST_SUCCESS',
    SEARCH_REQUEST_FAILED = 'SEARCH_REQUEST_FAILED',
    CHANGE_RESULTS_PER_PAGE = 'CHANGE_RESULTS_PER_PAGE',
    CHANGE_PAGE = 'CHANGE_PAGE',
    REFRESH_PAGE = 'REFRESH_PAGE',
    CHANGE_START_DATE = 'CHANGE_START_DATE',
    CHANGE_END_DATE = 'CHANGE_END_DATE',
    CHANGE_ACCOUNT_NUMBER = 'CHANGE_ACCOUNT_NUMBER',
    CHANGE_LINE_OF_BUSINESS = 'CHANGE_LINE_OF_BUSINESS',
    INITIALIZE_GENERATE_REPORT_REQUEST = 'INITIALIZE_GENERATE_REPORT_REQUEST',
    REPORT_GENERATION_SUCCESS = 'REPORT_GENERATION_SUCCESS',
    REPORT_GENERATION_FAILED = 'REPORT_GENERATION_FAILED'
}

export type Action =
    | { type: actions.NO_SEARCH_CRITERIA }
    | { type: actions.INITIALIZE_ACCOUNTING_PAGE_SEARCH_REQUEST }
    | { type: actions.INITIALIZE_ACCOUNT_NUMBERS_SEARCH_REQUEST }
    | { type: actions.SEARCH_REQUEST_SUCCESS; page: PageInfo; results: AccountingPageSearchResults }
    | { type: actions.SEARCH_ACCOUNT_NUMBERS_REQUEST_SUCCESS; results: AccountAttributeDomain[] }
    | { type: actions.SEARCH_REQUEST_FAILED }
    | { type: actions.CHANGE_RESULTS_PER_PAGE; size: string }
    | { type: actions.CHANGE_PAGE; pageNumber: number }
    | { type: actions.REFRESH_PAGE }
    | { type: actions.CHANGE_START_DATE; beginDate: Date }
    | { type: actions.CHANGE_END_DATE; endDate: Date }
    | { type: actions.CHANGE_ACCOUNT_NUMBER; accountNumber?: string }
    | { type: actions.CHANGE_LINE_OF_BUSINESS; linesOfBusiness: string[] }
    | { type: actions.INITIALIZE_GENERATE_REPORT_REQUEST }
    | { type: actions.REPORT_GENERATION_SUCCESS }
    | { type: actions.REPORT_GENERATION_FAILED };

export function AccountingPageReducer(state: AccountingPageState, action: Action): AccountingPageState {
    switch (action.type) {
        case actions.NO_SEARCH_CRITERIA:
            return {
                ...state,
                isLoading: false
            };
        case actions.INITIALIZE_ACCOUNTING_PAGE_SEARCH_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case actions.INITIALIZE_ACCOUNT_NUMBERS_SEARCH_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case actions.SEARCH_REQUEST_SUCCESS:
            return {
                ...state,
                currentPage: action.page,
                searchResults: action.results,
                isLoading: false,
                searchExecuted: true
            };
        case actions.SEARCH_ACCOUNT_NUMBERS_REQUEST_SUCCESS:
            return {
                ...state,
                accountNumberResults: action.results,
                isLoading: false
            };
        case actions.SEARCH_REQUEST_FAILED:
            return {
                ...state,
                isLoading: false
            };
        case actions.CHANGE_RESULTS_PER_PAGE:
            return {
                ...state,
                nextPage: {
                    ...state.currentPage,
                    size: parseInt(action.size),
                    page: 1
                }
            };
        case actions.CHANGE_PAGE:
            return {
                ...state,
                nextPage: {
                    ...state.currentPage,
                    page: action.pageNumber
                }
            };
        case actions.REFRESH_PAGE:
            return {
                ...state,
                nextPage: {
                    ...state.nextPage,
                    page: 1
                }
            };
        case actions.CHANGE_START_DATE:
            return {
                ...state,
                searchExecuted: false,
                displayCriteria: {
                    ...state.displayCriteria,
                    beginDate: action.beginDate.toISOString()
                },
                searchCriteria: {
                    ...state.searchCriteria,
                    beginDate: new Date(action.beginDate.getTime() - action.beginDate.getTimezoneOffset() * 60 * 1000)
                        .toISOString()
                        .split('T')[0]
                }
            };
        case actions.CHANGE_END_DATE:
            return {
                ...state,
                searchExecuted: false,
                displayCriteria: {
                    ...state.displayCriteria,
                    endDate: action.endDate.toISOString()
                },
                searchCriteria: {
                    ...state.searchCriteria,
                    endDate: new Date(action.endDate.getTime() - action.endDate.getTimezoneOffset() * 60 * 1000)
                        .toISOString()
                        .split('T')[0]
                }
            };
        case actions.CHANGE_ACCOUNT_NUMBER:
            return {
                ...state,
                searchExecuted: false,
                searchCriteria: {
                    ...state.searchCriteria,
                    accountNumber: action.accountNumber
                },
                displayCriteria: {
                    ...state.displayCriteria,
                    accountNumber: action.accountNumber
                }
            };
        case actions.CHANGE_LINE_OF_BUSINESS:
            return {
                ...state,
                searchExecuted: false,
                searchCriteria: {
                    ...state.searchCriteria,
                    linesOfBusiness: action.linesOfBusiness
                },
                displayCriteria: {
                    ...state.displayCriteria,
                    linesOfBusiness: action.linesOfBusiness
                }
            };
        case actions.INITIALIZE_GENERATE_REPORT_REQUEST:
            return {
                ...state,
                isGeneratingReport: true
            };
        case actions.REPORT_GENERATION_SUCCESS:
            return {
                ...state,
                isGeneratingReport: false
            };
        case actions.REPORT_GENERATION_FAILED:
            return {
                ...state,
                isGeneratingReport: false
            };
        default:
            throw new Error(`Unhandled action type: ${(action as unknown as Action).type}`);
    }
}
