import React from 'react';
import * as PropTypes from 'prop-types';
import { NavigationLink } from './NavigationLink';
import { SecondaryNavigation } from './SecondaryNavigation';

interface TransactionsNavigationProps {
    secondarySection?: string;
}

const TransactionsNavigation = (props: TransactionsNavigationProps) => {
    const { secondarySection } = props;

    return (
        <SecondaryNavigation>
            <NavigationLink
                title="Accounting"
                link="/transactions/accounting"
                active={secondarySection === 'accounting'}
            />
            <NavigationLink title="Product" link="/transactions/product" active={secondarySection === 'product'} />
        </SecondaryNavigation>
    );
};

TransactionsNavigation.propTypes = {
    secondarySection: PropTypes.string.isRequired
};

export default TransactionsNavigation;
