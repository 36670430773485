import { SilentRequest } from '@azure/msal-browser';

const silentRequestNonProd: SilentRequest = {
    scopes: ['api://d909899b-01da-4baa-b328-302a232cddaa/CloudFront.Full']
};

const silentRequestProd: SilentRequest = {
    scopes: ['api://d9d4e394-e823-42d6-a8e8-bdbb93e2661d/CloudFront.Full']
};

export function getSilentRequest(): SilentRequest {
    return window.location.hostname === 'accounting.salescompadmin.staging.principal.com' ||
        window.location.hostname === 'accounting.salescompadmin.dev.principal.com' ||
        window.location.hostname === 'localhost'
        ? silentRequestNonProd
        : silentRequestProd;
}
