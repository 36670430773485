import React from 'react';
import * as PropTypes from 'prop-types';
import './ErrorEventModal.css';
import { ErrorEvent } from '../../models/ErrorEvent';

const FailedErrorEventModal = (
    props: Pick<ErrorEvent, '_id' | 'recycleEvent' | 'source' | 'errorText' | 'errorMessage'>
) => {
    const { _id, errorText, source, recycleEvent, errorMessage } = props;

    return (
        <>
            <div
                className={'modal fade view-error' + _id}
                tabIndex={-1}
                role="dialog"
                aria-labelledby="my-large-modal-label"
            >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h2 className="modal-title" id="static-modal-label">
                                Error Details
                            </h2>
                        </div>
                        <div className="modal-body">
                            <p>
                                <>
                                    {errorMessage}
                                    <br />
                                </>
                            </p>
                            <div>
                                <b> Error Title </b>: {errorText}
                                <br />
                                <b> Error Id </b>: {_id}
                                <br />
                                <b> Error Source </b>: {source}
                                <br />
                                <div className="collapse-section">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="collapse-heading">
                                                <input
                                                    type="checkbox"
                                                    id="errorEventModalCollapse"
                                                    data-toggle="collapse"
                                                    data-target={'.row-to-collapse-errorEventModal'}
                                                />
                                                <label htmlFor="errorEventModalCollapse">
                                                    <span className="collapse-title">Event Payload</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        id="errorEventModal"
                                        className="row collapse row-to-collapse-errorEventModal util-padding-top-5"
                                        aria-expanded="false"
                                        data-testid="event-payload"
                                    >
                                        <pre> {JSON.stringify(JSON.parse(recycleEvent), null, '\t')} </pre>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

FailedErrorEventModal.propTypes = {
    _id: PropTypes.string.isRequired,
    errorText: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    recycleEvent: PropTypes.string.isRequired
};

export default FailedErrorEventModal;
