import React, { ReactNode } from 'react';
import * as PropTypes from 'prop-types';
import './Header.css';
import apiRoutes from '../../../api/apiRoutes';
import { AuthProfile } from '../../authentication/auth-profile';
import { useMsal } from '@azure/msal-react';

interface HeaderProps {
    children: ReactNode;
}

const Header = (props: HeaderProps) => {
    const { children } = props;
    const { instance } = useMsal();
    const idTokenClaims: AuthProfile = instance.getActiveAccount()?.idTokenClaims as AuthProfile;

    return (
        <header>
            <nav className="navbar navbar-default" data-testid={'pageMainHeader'}>
                <div className="container-fluid">
                    <div className="navbar-header">
                        <button
                            type="button"
                            className="navbar-toggle collapsed"
                            data-toggle="collapse"
                            data-target="#general-ledger-ui-nav-collapse"
                            aria-expanded="false"
                        >
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                        </button>
                        <a className="navbar-principal-brand" href="#">
                            {' '}
                        </a>
                    </div>

                    <div className="collapse navbar-collapse" id="general-ledger-ui-nav-collapse">
                        <ul className="nav navbar-nav">{children}</ul>

                        <ul className="nav navbar-nav navbar-right">
                            <li className="dropdown">
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                    <span>{idTokenClaims.name} </span>
                                    <i className="fa fa-angle-down" />
                                </a>
                                <ul className="dropdown-menu" role="menu">
                                    <li>
                                        <a href={apiRoutes.logout()}>Logout</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <nav className="bg-midnight navbar-business-line-brand">
                <span>SC Accounting</span>
            </nav>
        </header>
    );
};

Header.propTypes = {
    children: PropTypes.node.isRequired
};

export default Header;
