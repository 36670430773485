import { useMsal } from '@azure/msal-react';
import * as PropTypes from 'prop-types';
import { AuthProfile } from '../../authentication/auth-profile';
import { AuthorizationRules } from '../../authentication/authorization-rules';
import { NavigationBlock } from './NavigationBlock';
import { NavigationLink } from './NavigationLink';
import { SecondaryNavigation } from './SecondaryNavigation';

interface MappingsNavigationProps {
    secondarySection?: string;
}

export const MappingsNavigation = (props: MappingsNavigationProps) => {
    const { secondarySection } = props;
    const { instance } = useMsal();
    const idTokenClaims: AuthProfile = instance.getActiveAccount()?.idTokenClaims as AuthProfile;

    const canBuild = AuthorizationRules.canBuild(idTokenClaims);
    const items: { title: string; link: string; disabled?: boolean }[] = [
        {
            title: 'View',
            link: '/mappings/accounts'
        }
    ];
    if (canBuild) {
        items.push({
            title: 'Build',
            link: '/mappings/accounts/build'
        });
    }

    return (
        <SecondaryNavigation>
            <NavigationLink title="Products" link="/mappings/products" active={secondarySection === 'products'} />
            <NavigationBlock title="Accounts" items={items} active={secondarySection === 'accounts'} />
        </SecondaryNavigation>
    );
};

MappingsNavigation.propTypes = {
    secondarySection: PropTypes.string.isRequired
};
