import { useCallback } from 'react';
import Pageable from '../../layout/pageable/Pageable';
import isEmpty from 'lodash/isEmpty';
import Loading from '../../layout/loading/Loading';
import { useFailedErrorsState } from './FailedErrorsProvider';
import { actions } from './reducer';
import { FailedErrorsTable } from './FailedErrorsTable';

export const FailedErrorsResults = () => {
    const {
        state: { currentPage, isLoading, visibleErrorEvents },
        dispatch
    } = useFailedErrorsState();

    const onChangeResultsPerPage = useCallback(
        ({ value }: { value: string }) => {
            dispatch({ type: actions.CHANGE_RESULTS_PER_PAGE, size: value });
        },
        [dispatch]
    );

    const onChangePage = useCallback(
        (value: number) => {
            dispatch({ type: actions.CHANGE_PAGE, pageNumber: value });
        },
        [dispatch]
    );

    const hasResults = !isEmpty(visibleErrorEvents);
    const totalResults = hasResults ? visibleErrorEvents.length : 0;

    return (
        <>
            <Pageable
                hasResults={hasResults}
                totalResults={totalResults}
                page={currentPage}
                onChangePage={onChangePage}
                showChangeResultsPerPage={true}
                onChangeResultsPerPage={onChangeResultsPerPage}
                showRefreshButton={true}
                onRefreshPage={() => dispatch({ type: actions.REFRESH_PAGE })}
            >
                <div>
                    {isLoading && <Loading />}

                    {!isLoading && !hasResults && <div className="panel-body text-center">No results were found!</div>}

                    {hasResults && <FailedErrorsTable />}
                </div>
            </Pageable>
        </>
    );
};
