import React, { MouseEvent } from 'react';
import * as PropTypes from 'prop-types';
import { doNothingWithEvent } from '../../utils';

const PageNavigationRightButtons = (props: {
    disabled: boolean;
    onGoForward: (event: MouseEvent) => void;
    onGoToEnd: (event: MouseEvent) => void;
    showGoToEndButton: boolean;
}) => {
    const { disabled, onGoForward, onGoToEnd, showGoToEndButton } = props;

    return (
        <>
            <li className={disabled ? 'disabled' : ''}>
                <a href="#" onClick={disabled ? doNothingWithEvent : onGoForward}>
                    <i data-testid="pageable-single-arrow" className="fa fa-angle-right" />
                </a>
            </li>
            {showGoToEndButton && (
                <li className={disabled ? 'disabled' : ''}>
                    <a href="#" onClick={disabled ? doNothingWithEvent : onGoToEnd}>
                        <i data-testid="pageable-single-arrow" className="fa fa-angle-right" />
                        <i data-testid="pageable-single-arrow" className="fa fa-angle-right" />
                    </a>
                </li>
            )}
        </>
    );
};

PageNavigationRightButtons.propTypes = {
    disabled: PropTypes.bool,
    showGoToEndButton: PropTypes.bool,
    onGoForward: PropTypes.func.isRequired,
    onGoToEnd: PropTypes.func.isRequired
};

PageNavigationRightButtons.defaultProps = {
    disabled: false,
    showGoToEndButton: true
};

export default PageNavigationRightButtons;
