import { isEmpty } from 'lodash';
import Loading from '../layout/loading/Loading';
import { useAccountsState } from './AccountsProvider';
import AccountCollapsible from './AccountCollapsible';
import Pageable from '../layout/pageable/Pageable';
import { useCallback, useEffect } from 'react';
import { actions } from './reducer';
import { useAccountsSearch } from './useAccountsSearch';

export const AccountsSearchResults = () => {
    const {
        state: { isLoading, searchExecuted, accounts, currentPage, pageLoadNeeded },
        dispatch
    } = useAccountsState();

    const { getAccounts } = useAccountsSearch();

    const onChangeResultsPerPage = useCallback(
        ({ value }: { value: string }) => {
            dispatch({ type: actions.CHANGE_RESULTS_PER_PAGE, size: value });
        },
        [dispatch]
    );

    const onChangePage = useCallback(
        (value: number) => {
            dispatch({ type: actions.CHANGE_PAGE, page: value });
        },
        [dispatch]
    );

    useEffect(() => {
        if (pageLoadNeeded) {
            getAccounts();
            dispatch({ type: actions.SET, key: 'pageLoadNeeded', value: false });
        }
    }, [getAccounts, pageLoadNeeded]);

    const hasResults = !isEmpty(accounts);

    return (
        <>
            <Pageable
                hasResults={hasResults}
                totalResults={accounts?.length || 0}
                page={currentPage}
                onChangePage={onChangePage}
                showChangeResultsPerPage={true}
                onChangeResultsPerPage={onChangeResultsPerPage}
                showRefreshButton={true}
                onRefreshPage={() => dispatch({ type: actions.REFRESH_PAGE })}
            >
                <div>
                    {isLoading && <Loading />}
                    {!isLoading && searchExecuted && (
                        <div>
                            {hasResults ? (
                                <div>
                                    {accounts?.map((account) => (
                                        <AccountCollapsible account={account} key={account._id} />
                                    ))}
                                </div>
                            ) : (
                                <div className="text-center">No results were found!</div>
                            )}
                        </div>
                    )}
                </div>
            </Pageable>
        </>
    );
};
