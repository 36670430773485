import React, { useEffect } from 'react';
import { FailedErrorsResults } from './FailedErrorsResults';
import { useFailedErrorsSearch } from './useFailedErrorsSearch';

const FailedErrorsSearchContainer = () => {
    const { failedErrorEvents } = useFailedErrorsSearch();

    useEffect(() => {
        failedErrorEvents();
    }, [failedErrorEvents]);

    return (
        <>
            <h2>Failed Errors</h2>
            <FailedErrorsResults />
        </>
    );
};

export default FailedErrorsSearchContainer;
