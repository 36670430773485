import React from 'react';
import { AccountingPageSearchCriteria } from './AccountingPageSearchCriteria';
import { AccountingPageSearchResults } from './AccountingPageSearchResults';

const AccountingPageContainer = () => {
    return (
        <>
            <AccountingPageSearchCriteria />
            <AccountingPageSearchResults />
        </>
    );
};

export default AccountingPageContainer;
