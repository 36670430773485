import { IPublicClientApplication } from '@azure/msal-browser';
import axios from 'axios';
import { getSilentRequest } from '../app/authentication/get-silent-request';

export async function get<T>(
    url: string,
    instance: IPublicClientApplication,
    params: { [key: string]: string | number | string[] } = {}
): Promise<T> {
    const tokenResponse = await instance.acquireTokenSilent(getSilentRequest());

    const config = {
        params,
        headers: {
            Authorization: `Bearer ${tokenResponse.accessToken}`
        },
        paramsSerializer: {
            indexes: null
        }
    };

    const response = await axios.get(url, config);
    return response.data;
}
