import React, { ChangeEvent, useCallback } from 'react';
import * as PropTypes from 'prop-types';
import FormGroup from './FormGroup';
import Label from './Label';

const TextField = (props: {
    id: string;
    name?: string;
    defaultValue?: string | number;
    label: string;
    className?: string;
    maxLength?: number;
    required?: boolean;
    disabled?: boolean;
    placeholder?: string;
    fieldErrors?: object;
    autoFocus?: boolean;
    type?: string;
    onChange?: (value: { id: string; value: string; event: ChangeEvent<HTMLInputElement> }) => void;
}) => {
    const { id, name, defaultValue, label, className, maxLength, required, disabled, placeholder, autoFocus, type } =
        props;

    const onChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            if (props.onChange) {
                props.onChange({
                    id: event.target.id,
                    value: event.target.value,
                    event: event
                });
            }
        },
        [props]
    );

    return (
        <FormGroup id={id}>
            <Label id={id} label={label} required={required} />
            <input
                autoFocus={autoFocus}
                id={id}
                name={name || id}
                type={type}
                className={'form-control ' + className}
                placeholder={placeholder}
                defaultValue={defaultValue}
                onChange={onChange}
                disabled={disabled}
                maxLength={maxLength}
            />
        </FormGroup>
    );
};

TextField.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object]),
    className: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    type: PropTypes.string,
    fieldErrors: PropTypes.object
};

TextField.defaultProps = {
    label: '',
    className: '',
    defaultValue: '',
    type: 'text'
};

export default TextField;
