import { ProtocolMode } from '@azure/msal-browser';

export const msalConfigurationLocal = {
    auth: {
        clientId: 'd909899b-01da-4baa-b328-302a232cddaa',
        authority: 'https://login.microsoftonline.com/50abac53-da5d-44dc-8586-587ec2d3d097',
        knownAuthorities: ['https://login.microsoftonline.com/50abac53-da5d-44dc-8586-587ec2d3d097/v2.0'],
        redirectUri: 'http://localhost:3000',
        protocolMode: ProtocolMode.AAD
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false
    }
};

export const msalConfigurationDev = {
    auth: {
        clientId: 'd909899b-01da-4baa-b328-302a232cddaa',
        authority: 'https://login.microsoftonline.com/50abac53-da5d-44dc-8586-587ec2d3d097',
        knownAuthorities: ['https://login.microsoftonline.com/50abac53-da5d-44dc-8586-587ec2d3d097/v2.0'],
        redirectUri: 'https://accounting.salescompadmin.dev.principal.com/index.html',
        protocolMode: ProtocolMode.AAD
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false
    }
};

export const msalConfigurationNonprod = {
    auth: {
        clientId: 'd909899b-01da-4baa-b328-302a232cddaa',
        authority: 'https://login.microsoftonline.com/50abac53-da5d-44dc-8586-587ec2d3d097',
        knownAuthorities: ['https://login.microsoftonline.com/50abac53-da5d-44dc-8586-587ec2d3d097/v2.0'],
        redirectUri: 'https://accounting.salescompadmin.staging.principal.com/index.html',
        protocolMode: ProtocolMode.AAD
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false
    }
};

export const msalConfigurationProd = {
    auth: {
        clientId: 'd9d4e394-e823-42d6-a8e8-bdbb93e2661d',
        authority: 'https://login.microsoftonline.com/3bea478c-1684-4a8c-8e85-045ec54ba430',
        knownAuthorities: ['https://login.microsoftonline.com/3bea478c-1684-4a8c-8e85-045ec54ba430/v2.0'],
        redirectUri: 'https://accounting.salescompadmin.principal.com/index.html',
        protocolMode: ProtocolMode.AAD
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false
    }
};
