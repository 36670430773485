import * as PropTypes from 'prop-types';
import './ErrorEventModal.css';

const ResourcePayloadModal = (props: { _id: string; payload: object; title: string }) => {
    const { _id, payload, title } = props;
    return (
        <>
            <div
                className={'modal fade view-payload' + _id}
                tabIndex={-1}
                role="dialog"
                aria-labelledby="my-large-modal-label"
            >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h2 className="modal-title" id="static-modal-label">
                                {title}
                            </h2>
                        </div>
                        <div className="modal-body">
                            <div>
                                <pre> {JSON.stringify(payload, null, '\t')} </pre>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

ResourcePayloadModal.propTypes = {
    _id: PropTypes.string.isRequired,
    payload: PropTypes.any.isRequired
};

export default ResourcePayloadModal;
